import React, { useState } from 'react';
import { getOrders, primaryActionsMap, printLabels } from '../../../../../services/panel.service';
import statusSettings from "../../../../../config/statusSettings.js"
import { useGeneralStore, useModalsStore, usePanelStore } from '../../../../../store';

// Buttons
import { FadeIn, Button } from 'react-package';

// Components
import SecondaryActions from './SecondaryActions';

const PrimaryButton = ({ action, order }) => {
  const [loading, setLoading] = useState(false);
  const { setOrders } = usePanelStore(state => state);
  const { store } = useGeneralStore(state => state);
  const { handleNotification } = useModalsStore(state => state);

  const handleClick = async () => {
    if (loading) return;
    setLoading(true);
    try {
      await primaryActionsMap[action.id](order.orderId)

      const primaryAction = statusSettings[order.trackingStatus]?.primaryActions.find(a => a.id === action.id);

      if (!primaryAction?.noReload) {
        setTimeout(async () => {
          const orders = await getOrders(store)
          if (orders?.data) {
            setOrders(orders.data)
            console.log(orders)
          }
        }, 2500)
      }
    } catch (error) {
      console.log("Error", error.message)
      handleNotification("error", error.message, 6000)
    }
    setLoading(false);
  }

  return (
    <Button
      key={action.id}
      id={action.id}
      text={action.text}
      variants="sm"
      bgColor="#F52F41"
      alt
      onClick={() => handleClick()}
      disabled={loading}
    />
  )
}

const Order = ({ order, i }) => {
  const color = useGeneralStore(state => state.settings.colors.primary);
  const { activePage, resultsPerPage, selectOrder } = usePanelStore(state => state);
  const finalColor = statusSettings[order.trackingStatus]?.color || color

  return (
    <FadeIn delay={(i / 25) - ((activePage - 1) * resultsPerPage) / 25}
      className="row-panel order">
      {/* <div
        onClick={() => selectOrder(order.orderId)}
        className="col-panel" id="selector"
      >
        <input
          type="checkbox"
          name="selector"
          id="selector"
          checked={order.selected}
        />
      </div> */}

      <div className="col-panel" id="orderNumber">
        {order.orderNumber}
      </div>

      <div className="col-panel" id="trackingId">
        {order.confirmationCode}
      </div>

      {/* Status */}
      <div style={{
        color: `#${finalColor}`,
      }} className="col-panel" id="status">
        <div className="status-tooltip">
          <div className="text">
            {order.logisticMode === "CROSS_DOCKING" ?
              (statusSettings[order.trackingStatus]["XD-tooltip"] || statusSettings[order.trackingStatus]?.tooltip) :
              (statusSettings[order.trackingStatus]?.tooltip || order.text || order.trackingStatus)
            }

          </div>
        </div>
        {order.logisticMode === "CROSS_DOCKING" ?
          (statusSettings[order.trackingStatus]["XD-text"] || statusSettings[order.trackingStatus]?.text || order.trackingStatus) :
          (statusSettings[order.trackingStatus]?.text || order.trackingStatus)}
      </div>

      <div className="col-panel" id="shippingType">
        {order.orderData.shipping_option_code === "pedidosya" ? "Inmediato" : "Franjas Horarias"}
      </div>

      <div className="col-panel" id="date">
        {new Date(order.dateCreated).toLocaleDateString("es-ES")}
      </div>

      {/* Primary Actions */}
      <FadeIn delay={0.5} className="col-panel" id="buttons">
        {
          statusSettings[order.trackingStatus]?.primaryActions.map(action => (
            <PrimaryButton key={action.id} action={action} order={order} />
          ))
        }
      </FadeIn>

      <SecondaryActions order={order} status={order.trackingStatus} i={i} />

    </FadeIn>
  );
}

export default Order;
