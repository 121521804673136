import React from 'react';
import statusSettings from "../../../../../config/statusSettings.js"
import { printLabels } from '../../../../../services/panel.service';

// Icons
import { FaHeadphones } from "react-icons/fa";
import { RiImageFill } from 'react-icons/ri';
import { HiOutlinePencilAlt, HiPrinter } from 'react-icons/hi';
import { MdOutlineRotateRight, MdClose } from 'react-icons/md';
import { GrMapLocation } from 'react-icons/gr';

const SecondaryActions = ({ status, order, i }) => {
  const openProofOfDelivery = () => {
    // Convert base64 image to blob for opening in new tab
    const base64ImageData = order.proofOfDelivery
    const contentType = 'image/png';

    const byteCharacters = atob(base64ImageData.substr(`data:${contentType};base64,`.length));
    const byteArrays = [];

    for (let offset = 0; offset < byteCharacters.length; offset += 1024) {
      const slice = byteCharacters.slice(offset, offset + 1024);

      const byteNumbers = new Array(slice.length);
      for (let i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }

      const byteArray = new Uint8Array(byteNumbers);

      byteArrays.push(byteArray);
    }
    const blob = new Blob(byteArrays, { type: contentType });
    const blobUrl = URL.createObjectURL(blob);

    window.open(blobUrl, '_blank');
  }

  const Tooltip = ({ text }) => {
    const inverted = i === 0
    return (
      <div className={`action-tooltip ${inverted && "inverted"}`}>
        <div className="text">
          {text}
        </div>
      </div>
    )
  }

  return (
    <div className="col-panel" id="actions">
      {
        statusSettings[status]?.secondaryActions.edit &&
        <div id="edit" className="action">
          <HiOutlinePencilAlt />
        </div>
      }
      {
        statusSettings[status]?.secondaryActions.retry &&
        <div id="retry" className="action">
          <MdOutlineRotateRight />
        </div>
      }
      {/* {
        statusSettings[status]?.secondaryActions.print &&
        <div onClick={() => handlePrint(order)} id="print" className="action">
          <FiPrinter />
        </div>
      } */}

      {
        statusSettings[status]?.secondaryActions.cancel &&
        <div id="cancel" className="action">
          <MdClose />
        </div>
      }

      {
        statusSettings[status]?.secondaryActions.tracking &&
        <div
          onClick={() => window.open(order.trackingUrl, '_blank').focus()}
          id="tracking" className="action">

          <Tooltip text="Seguimiento" />
          <GrMapLocation />
        </div>
      }


      {
        statusSettings[status]?.secondaryActions.proofOfDelivery &&
        <div onClick={() => { openProofOfDelivery() }} id="proofOfDelivery" className="action">
          <Tooltip text="Prueba de entrega" />
          <RiImageFill />
        </div>
      }

      {
        statusSettings[status]?.secondaryActions.help &&
        <div id="help" className="action" onClick={() => {
          window.open(order.onlineSupportUrl, '_blank').focus()
        }} >
          <Tooltip text="Obtener ayuda" />
          <FaHeadphones />
        </div>
      }

      {
        statusSettings[status]["XD-secondaryActions"]?.label &&
        <div id="label" className="action" onClick={() => {
          // Download base 64 pdf file
          printLabels(order)
        }} >
          <Tooltip text="Descargar etiqueta" />
          <HiPrinter style={{ fontSize: "1.5rem" }} />
        </div>
      }
    </div>
  )
}

export default SecondaryActions;
