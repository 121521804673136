import {
  Combobox,
  ComboboxInput,
  ComboboxList,
  ComboboxOption,
  ComboboxPopover,
} from '@reach/combobox';
import { useState, useEffect } from 'react';
import { getLatLng } from 'react-places-autocomplete';
import usePlacesAutocomplete, { getGeocode } from 'use-places-autocomplete';
import '@reach/combobox/styles.css';
import { useAuthStore, useStorageFormStore } from '../../store';
import { checkCoverage } from '../../services/delivery.service';
import { motion, AnimatePresence } from 'framer-motion';
import { comeFromLeftAn } from '../../services/shared/framermotion.service';
import { FiInfo } from 'react-icons/fi';
import { AiOutlineClose } from 'react-icons/ai';

export const CustomAutoComplete = ({ setSelected, setBody, body, tabIndex }) => {
  const {
    ready,
    value,
    setValue,
    suggestions: { status, data },
    clearSuggestions,
  } = usePlacesAutocomplete();
  const storageAddress = useStorageFormStore((state) => state.store?.address);
  const isOnCoverage = useStorageFormStore((state) => state.isOnCoverage);
  const changeCoverage = useStorageFormStore((state) => state.changeCoverage);
  const [isLoading, setIsLoading] = useState(true);
  const [isFailed, setIsFailed] = useState('a');
  const [displayAlert, setDisplayAlert] = useState(false);
  const userId = useAuthStore((state) => state.initialBody.userId);

  const [firstLoad, setFirstLoad] = useState(true); // To avoid out of range to appear on first

  useEffect(() => {
    if (storageAddress) {
      handleSelect(storageAddress);
      setIsLoading(false);
    }
  }, [storageAddress]);

  useEffect(() => {
    if (isFailed) {
      setTimeout(() => {
        setIsFailed(false);
      }, 3000);
    }
  }, [isFailed]);

  const handleSelect = async (address) => {
    setIsFailed(true);
    setIsLoading(true);
    setValue(address, false);
    clearSuggestions();
    
    try {
      const results = await getGeocode({ address });
      const { lat, lng } = await getLatLng(results[0]);
      const coverage = await checkCoverage({ lat: lat, lng: lng }, userId);
      const { success: coverageSuccess } = coverage;
      changeCoverage(coverageSuccess);
      setDisplayAlert(coverageSuccess);
      if (coverageSuccess) {
        setBody({ ...body, address: address, coordinates: { lat: lat, lng: lng } });
      } else {
        setBody({ ...body, address: null, coordinates: { lat: null, lng: null } });
      }
      setSelected({ lat, lng });
    } catch (error) {
      changeCoverage(false);
      setDisplayAlert(true);
    }
    setIsLoading(false);
  };

  return (
    <Combobox onSelect={handleSelect}>
      <label className="fw-bold purple-text">Dirección de retiro</label>
      <ComboboxInput
        tabIndex={1}
        value={value}
        onChange={(e) => setValue(e.target.value)}
        className="combobox-input"
        placeholder="Av. 20 de Noviembre, Ciudad de Buenos Aires"
      />
      <AnimatePresence>
        {!isOnCoverage && (
          <motion.strong {...comeFromLeftAn} className="error d-flex flex-row align-items-center">
            <p>
              <FiInfo size={'1.25rem'} />
              La dirección está fuera de nuestra área de servicio. Por favor, ingresá otra.
            </p>
            <p>
              <AiOutlineClose onClick={() => setDisplayAlert(false)} size={'1.25rem'} />
            </p>
          </motion.strong>
        )}
      </AnimatePresence>
      <ComboboxPopover>
        <ComboboxList>
          {status === 'OK' &&
            data.map(({ place_id, description }) => (
              <ComboboxOption key={place_id} value={description} />
            ))}
        </ComboboxList>
      </ComboboxPopover>
    </Combobox>
  );
};
