import create from 'zustand';
import { devtools, persist } from 'zustand/middleware';
// import { mountStoreDevtool } from 'simple-zustand-devtools';
//Auth
import { generalStore } from "./store/general"
import { panelStore } from './store/panel';
import { modalsStore } from "./store/modals";

let auth = set => ({
  alert: { success: null, message: null },
  initialBody: { storeName: null, storeUrl: null, userId: null, appKey: null, appKeyPeya: null },
  setInitialBody: (data) => set(state => ({ initialBody: data })),
  setAlert: (data) => set(state => ({ alert: data })),
  cleanAlert: () => set(state => ({ alert: { success: null, message: null } }))
});

let storageForm = set => ({
  store: {
    address: "",
    managerName: "",
    phoneNumber: "",
    instructions: "",
    preparationTime: 0, // En horas
    immediateShip: false,
    sameDayShip: false,
    timeSchedule: [],
    isEveryOpenDayConfigurated: false,
    isAutomatic: true,
    deliveryType: "",
  },
  isOnCoverage: false,
  changeCoverage: (data) => set((state) => ({ isOnCoverage: data })),
  addData: (data) => set({ store: data }),
})

let nav = set => ({
  step: { count: 1, redirect: null },
  animateLeave: false,
  setAnimateLeave: (data) => set(state => ({ animateLeave: data })),
  setCurrentStep: (data) => set(state => ({ step: data })),
  partnerHasTimezoneAssigned: false,
  setPartnerHasTimezoneAssigned: (data) => set(state => ({ partnerHasTimezoneAssigned: data })),
});

auth = persist(auth, { name: 'shared' });
storageForm = persist(storageForm, { name: 'storage' })
nav = persist(nav, { name: 'navigation' })
auth = devtools(auth);
storageForm = devtools(storageForm)
nav = devtools(nav)

export const useAuthStore = create(auth);
export const useStorageFormStore = create(storageForm)
export const useNavStore = create(nav);
export const useGeneralStore = create(generalStore);
export const usePanelStore = create(panelStore);
export const useModalsStore = create(modalsStore);