import React from 'react';
import { motion } from 'framer-motion';
import { InputsCheck, InputsRadio } from '../shared/UI';
import { deliveryAn } from '../../services/shared/framermotion.service';
import LoaderGif from '../../assets/loading.svg';
import { useDelivery } from '../../services/hooks/useDelivery';

export const RequestDeliveries = ({ stageChangeFn }) => {
  const {
    isLoading,
    handleRadioChangeRequestForm: handleRadioChange,
    handleCheckboxChange,
    requestDealerType,
    deliveryType,
    onSubmit,
  } = useDelivery(true, stageChangeFn);
  return (
    <motion.section
      key="delivery-container"
      id="delivery-container"
      className="d-flex flex-column justify-content-start"
      {...deliveryAn}
    >
      <h2 className="fw-bold mb-0 mt-2 mt-xxl-4">
        Configurá cómo solicitar repartidores para tus envíos
      </h2>
      <form
        onSubmit={onSubmit}
        className="d-flex flex-column mb-3 mb-xxl-4"
        id="form-request-delivery"
      >
        <motion.div className="mt-3 mt-xxl-4 ">
          <InputsRadio
            id="automatic"
            name="requestDealerType"
            value="automatic"
            extraClassname="radio-margin-bottom"
            disabled={isLoading}
            labelTxt="Solicitar repartidores de forma automática"
            descriptionTxt="Asignaremos repartidores de acuerdo a los envíos que pidan tus clientes y al tiempo que configuraste para la preparación de tus paquetes."
            onChangeFn={handleRadioChange}
            checked={requestDealerType === 'automatic'}
          />
          <InputsRadio
            id="manual"
            name="requestDealerType"
            value="manual"
            extraClassname="radio-margin-bottom margin-fixed-fee"
            disabled={isLoading}
            labelTxt="Solicitar repartidores de forma manual"
            descriptionTxt="Tendrás que solicitar manualmente los repartidores para cada envío que pidan tus clientes. "
            onChangeFn={handleRadioChange}
            checked={requestDealerType === 'manual'}
          />
        </motion.div>

        <section>
          <summary>Ofrecerás estos envíos en tu tienda</summary>
          <InputsCheck
            id="sameDayShip"
            name="deliveryType"
            value="sameDayShip"
            extraClassname="radio-margin-bottom margin-fixed-fee"
            disabled={isLoading}
            labelTxt="Envíos inmediatos"
            descriptionTxt="Retiramos y entregamos el paquete a tu cliente lo antes posible."
            onChangeFn={handleCheckboxChange}
            checked={deliveryType === 'sameDayShip'}
          />
        </section>

        <footer className="d-flex justify-content-end align-items-center">
          <button
            type="button"
            onClick={() => stageChangeFn(2)}
            className="btn btn-outline-secondary px-5 me-5"
          >
            Volver
          </button>
          <button
            type="submit"
            className={`isDisabled-${isLoading} btn-onboarding finish`}
            disabled={isLoading}
          >
            {!isLoading ? 'Continuar' : <img src={LoaderGif} alt="Loading spinner" />}
          </button>
        </footer>
      </form>
    </motion.section>
  );
};
