import moment from 'moment';

const initialState = {
    orders: [],
    filteredOrders: [],
    activeTab: "orders",
    ordersActiveTab: "all",
    configActiveTab: "",
    resultsPerPage: 10,
    activePage: 1,
    totalPages: 1,
    startDate: null,
    endDate: null,
    search: "",
    selectAllOrdersStatus: false,
    loading: false,
    sidebarCollapsed: false
}

export default initialState