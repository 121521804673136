import React, { useState, useEffect } from 'react';
import {
    getInitialState,
} from "../../services/panel.service"
import {
    getOrders,
    printLabels
} from "../../services/panel.service"
import { useGeneralStore, useWelcomeStore, useModalsStore, usePanelStore }
    from '../../store';

// Components
import NotificationAlert from "../../components/NotificationAlert"
import Sidebar from './Sidebar';

// Views
import Stats from "./views/Stats"
import Orders from "./views/Orders"
import { AnimatePresence } from 'framer-motion';

const Panel = () => {
    // Estado global
    const { setGeneralStore, darkmode, store, token } = useGeneralStore(state => state)
    const { setOrders, orders, activeTab, setActiveTab } =
        usePanelStore(state => state)
    const { notification } = useModalsStore(state => state.general)

    // Get editing integration data
    useEffect(() => {
        const initialFetch = async () => {
            let query = new URLSearchParams(window.location.search);
            const userId = query.get('store')
            const token = query.get('token')
            const view = query.get('view')

            if (!userId && !token) {
                console.error("@@@ No userId or token provided")
                return
            }

            const response = await getInitialState(userId, token)
            if (response) {
                const { data } = response;

                setGeneralStore({
                    store: userId,
                    token,
                    storeName: data.storeName,
                    storeUrl: data.storeUrl,
                })

                const orders = await getOrders(userId, token)
                console.log(orders, data)
                if (orders?.data)
                    setOrders(orders.data)
            }

            setActiveTab(view || "orders")
        }
        initialFetch()
    }, []);

    // Fetch orders every 30 seconds
    useEffect(() => {
        if (store) {
            const fetchOrders = async () => {
                const orders = await getOrders(store, token)
                if (orders?.data)
                    setOrders(orders.data)
            }
            const interval = setInterval(fetchOrders, 60000)
            return () => clearInterval(interval)
        }
    }, [store])


    return (

        <div className={darkmode ? "darkmode page" : "page"} id="panel">
            <div className="container-panel">
                <div className="top-wrapper">
                    <div className="logos">
                        <img src="/logoPedidosYa.svg" alt="Tiendanube" />
                        <img src="/logoTiendanube.png" alt="Tiendanube" />
                    </div>
                    <NotificationAlert
                        visible={notification.visible}
                        type={notification.type}
                        message={notification.message}
                    />

                </div>

                <div className="bottom-wrapper">
                    <Sidebar />
                    <div className="bottom-wrapper__content">
                        {activeTab === 'orders' && <Orders />}

                        {/* {
                            activeTab === 'stats' &&
                            <Stats orders={orders} />
                        } */}
                    </div>
                </div>
            </div>

        </div>

    );
}

export default Panel;
