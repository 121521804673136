import React from 'react';
import { usePanelStore } from '../../../../store';

const Columns = () => {
  const { selectAllOrders, selectAllOrdersStatus } = usePanelStore(state => state);

  return (
    <div id="columns" className="row-panel ">
      {/* <div className="col-panel" id="selector">
        <input
          type="checkbox"
          name="selector"
          id="selector"
          checked={selectAllOrdersStatus}
          onChange={() => selectAllOrders(!selectAllOrdersStatus)}
        />
      </div> */}

      <div className="col-panel" id="orderNumber">
        N° de orden
      </div>
      <div className="col-panel" id="trackingId">
        PedidosYa ID
      </div>
      <div className="col-panel" id="status">
        Estado
      </div>
      <div className="col-panel" id="shippingType">
        Modalidad
      </div>
      <div className="col-panel" id="date">
        Fecha
      </div>
      <div className="col-panel" id="buttons">
        Acciones
      </div>
      <div className="col-panel" id="actions">
        Otras acciones
      </div>
    </div>
  );
}

export default Columns;
