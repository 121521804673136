import React, { forwardRef } from 'react';
import { FaCalendarAlt } from 'react-icons/fa';

const DateCustomInput = forwardRef(({ value, onClick, id, label }, ref) => (
  <div id={id} className="input sm no-label" ref={ref}>
    {/* <div className="icon">
      <FaCalendarAlt />
    </div> */}
    <input
      type="text"
      value={value}
      onClick={onClick}
      placeholder="Filtrar por fecha"
    />
  </div>
));

export default DateCustomInput;
