export const customerName = "PedidosYa Envíos"

export const customerRouteName = "pedidosya"

export const urlInfo = {
    tiendanube: {
        contactUs: "",
        createAccount: ""
    },
}

export const maxInputLength = 900;
