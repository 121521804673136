import React, { useState } from 'react'
import { motion } from 'framer-motion';
import { HiCheckCircle } from 'react-icons/hi'
import { comeFromLeftAn, fallingAnimation, finishAn } from '../../services/shared/framermotion.service';
import { AutomaticSetter } from './AutomaticSetter';
import { useNavigate } from 'react-router';
import { useAuthStore, useNavStore } from '../../store';

export const Finish = () => {
  const navigate = useNavigate();
  const userId = useAuthStore((state) => state.initialBody.userId);
  const [leave, setLeave] = useState(false); // workaround for leaving animation on return to delivery page
  const setCurrentStep = useNavStore((state) => state.setCurrentStep);
  const onPanel = () => {
    setLeave(true);
    setCurrentStep(5);
    setTimeout(() => {
      navigate(`../../../pedidosya/tn/panel?store=${userId}&view=orders`, { replace: true });
    }, 900);
  }
  return (
    <motion.section
      key='finish-container'
      id='finish-container'
      variants={finishAn}
      animate={leave && 'leave'}
      className='d-flex flex-column justify-content-center align-items-center'
    >
      <motion.aside className='mt-5' key='check-container' transition={{ duration: 0.2, delay: 0.5 }} {...fallingAnimation} >
        <HiCheckCircle id='ok-check' />
      </motion.aside>
      <motion.h2 className='mb-3 mb-xxl-4 fw-bold text-center' 
      key='finish-text' 
      transition={{
          duration: .6,
          delay: 1.5
        }}
        {...fallingAnimation}>
        <p className="m-0">Instalaste la aplicación</p>
        <p>PedidosYa está disponible en tu tienda</p>
      </motion.h2>
      <motion.h3
        key='finish-message'
        transition={{
          duration: 0.6,
          delay: 1.9,
        }}
        {...fallingAnimation}
      >
        <p>Cónocé cómo gestionar los envíos desde el Panel de envíos. </p>
      </motion.h3>
      <motion.div
        key='final-assesment'
        {...comeFromLeftAn}
        transition={{
          duration: .6,
          delay: 2.5
        }}
      >
        <footer className='d-flex flex-row justify-content-between mt-2 align-items-center'>
          <button onClick={onPanel} className={` go-to-panel btn-onboarding`}>
            Ir al panel de envíos
          </button>

        </footer>
      </motion.div>
    </motion.section>
  )
}
