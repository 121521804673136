import { AnimatePresence } from "framer-motion";
import React from "react";
import ReactDOM from "react-dom/client";
import { IndexRoutes } from "./Routes/AnimatedRoutes";
import "react-datepicker/dist/react-datepicker.css";


// if (process.env.DEVELOPMENT_STATUS=== 'mock') {
    // const { worker } = require('./mocks/browser')
    // worker.start()
// }
const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
    <React.StrictMode>
        <AnimatePresence>
            <IndexRoutes />
        </AnimatePresence>
    </React.StrictMode>
);