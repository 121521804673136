import { devtools, persist } from 'zustand/middleware';
import modalsInitialState from "./initialState/modals";

export let modalsStore = set => ({
    ...modalsInitialState,

    // Receives modal id and modal group (object parent)
    toggleModal: (modalGroup, modalName, values) => {
        console.log("TOGGLE MODAL")
        console.log(modalGroup, modalName, values)

        return set(state => ({
            [modalGroup]: {
                ...state[modalGroup],
                [modalName]: {
                    ...state[modalGroup][modalName],
                    ...values,
                },
            },
        }
        ))
    },

    handleNotification: (type, message, time) => {
        set(state => ({
            general: {
                ...state.general,
                notification: {
                    type,
                    message,
                    visible: true
                }
            }
        }
        ))

        setTimeout(() => {
            set(state => ({
                general: {
                    ...state.general,
                    notification: {
                        ...state.general.notification,
                        visible: false
                    }
                }
            }
            ))
        }, time || 4000)
    }
})


// modalsStore = persist(modalsStore, { name: 'modals' });
modalsStore = devtools(modalsStore);