import { useAuthStore, useNavStore, useStorageFormStore } from '../../store'
import { useState, useEffect } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { getCityName, timeOptions } from '../store.service';
import { getCountryCoords } from './../form.service';
import { deepCopy, validateLength, validatePhone } from '../shared/utils.service';
import { limitLength } from './../shared/utils.service';
import { useTabIndex } from 'react-tabindex';
import { weekdaysTemplate } from '../delivery.service';

export const useStore = () => {
  const store = useStorageFormStore((state) => state.store);
  const userId = useAuthStore((state) => state.initialBody?.userId);
  const [searchParams] = useSearchParams();
  const userIdParams = searchParams.get('userId');
  const cache = useStorageFormStore((state) => state.store);
  const addData = useStorageFormStore((state) => state.addData);

  const [stage, setStage] = useState(1); //Moves around 2 internal stages
  // ? Page 1
  const navigate = useNavigate();

  const [isLoading, setIsLoading] = useState(false);
  const [isFormDisabled, setIsFormDisabled] = useState(true);
  const [isFailed, setIsFailed] = useState();

  // minutes / days
  const determinateCustomTime = timeOptions.map((option) => option.value);
  const cacheHasCustomTime = !determinateCustomTime.includes(store?.preparationTime);
  const [isCustomTime, setIsCustomTime] = useState(cacheHasCustomTime);
  const reFormatting = cacheHasCustomTime ? 1400 : 1;

  const [preparationTime, setPreparationTime] = useState(
    store?.preparationTime / reFormatting || 4
  );
  const [showWarning, setShowWarning] = useState(false);
  const [isSubmitDisabled, setIsSubmitDisabled] = useState(true);
  const tabIndex = useTabIndex();

  // @@@ BODY LOAD
  const [body, setBody] = useState({
    additionalNotes: '',
    address: '',
    city: '',
    coordinates: { lat: 0, lng: 0 },
    instructions: '',
    isEveryOpenDayConfigurated: false,
    managerName: '',
    phoneNumber: '',
    preparationTime: 0,
    timeSchedule: [],
  });

  useEffect(() => {
    setBody(cache);
  }, []);

  // @@@ /BODY LOAD

  const { onChange, handlePreparationChange } = useChange(
    body,
    setBody,
    setPreparationTime,
    setShowWarning,
    setIsCustomTime
  );
  const {
    handleStartDate, handleEndDate,
    startDate, endDate,
    handleAvailableDays,
    everyOpenDayConfigurated, weekDays, timeErrors, openDays,
    allCompleted, disabledByDate
  } = usePeyaDatePicker(store, setIsFormDisabled);

  const { defaultCoordinates, locationInitialCoordinates } = useMap( cache, setIsLoading, body, setBody)

  useEffect(() => {
    let isPreparationTimeValid = true;
    if ( isCustomTime ) {
      isPreparationTimeValid = (preparationTime > 0) && (preparationTime <= 4)
    }
    setIsSubmitDisabled(!isPreparationTimeValid || !preparationTime || !allCompleted);
  }, [body, preparationTime, everyOpenDayConfigurated, isLoading, showWarning, allCompleted])


  useEffect(() => {
    const { managerName, phoneNumber, additionalNotes, address, coordinates, city, instructions } = body;
    const failedParams = {
      managerName: validateLength(managerName),
      phoneNumber: validatePhone(phoneNumber),
      additionalNotes: validateLength(additionalNotes),
      address: validateLength(address),
      instructions: limitLength(instructions)
    }
    setIsFailed({ failedParams })
    const formEnabled = (coordinates && address && city && managerName && validatePhone(phoneNumber))
    setIsFormDisabled(!formEnabled)
  }, [body])


  const setCurrentStep = useNavStore((state) => state.setCurrentStep);
  const handleStageChange = (target) => {
    setStage(target);
    setCurrentStep(target);
  };
  useEffect(() => {
    setCurrentStep(1);
  }, []);

  const handleSubmitTime = async (finalPreparationTime, allDaysDate) => {
    let deliveryBodyReplacer = {
      ...store,
      address: body?.address,
      city: body?.city,
      managerName: body?.managerName,
      coordinates: body?.coordinates,
      phoneNumber: body?.phoneNumber,
      additionalNotes: body?.additionalNotes,
      instructions: body?.instructions,
      preparationTime: finalPreparationTime,
      timeSchedule: allDaysDate || weekDays,
    };
    addData(deliveryBodyReplacer); // THIS ALLOWS THE OTHER FORM TO HANDLE
    setStage(3);
    handleStageChange(3);
  };

  const submitStepOneAndTwo = () => {
    let finalPreparationTime = isCustomTime ? preparationTime * 1400 : preparationTime;
    handleSubmitTime(finalPreparationTime);
  };

  return {
    // ? PART 1
    submitStepOneAndTwo,
    isLoading,
    isFailed,
    isFormDisabled,
    cache,
    body,
    setBody,
    onChange,
    stage,
    setStage,
    defaultCoordinates,
    locationInitialCoordinates,
    tabIndex,
    // ? PART 2
    handleAvailableDays,
    openDays,
    handleStartDate,
    handleEndDate,
    startDate,
    endDate,
    weekDays,
    preparationTime,
    timeErrors,
    allCompleted,
    handlePreparationChange,
    isCustomTime,
    showWarning,
    isSubmitDisabled,
    disabledByDate,
    userId,
    handleStageChange
  }
}

const usePeyaDatePicker = (store) => {
  const [openDays, setOpenDays] = useState();
  const timeScheduleCache = () => {
    return store?.timeSchedule.length > 0 ? store?.timeSchedule : [...weekdaysTemplate];
  };
  const [weekDays, setWeekDays] = useState(timeScheduleCache());
  //? Dates
  const [startDate, setStartDate] = useState(); // This is because the library automatically does this
  const [endDate, setEndDate] = useState();
  const [everyOpenDayConfigurated, setEveryOpenDayConfigurated] = useState(false);
  const [timeErrors, setTimeErrors] = useState([]); // can be turned on for hours limits
  const [allCompleted, setAllCompleted] = useState(false);

  useEffect(() => {
    let openDays = weekDays.filter((days) => days.open);
    if ( openDays.length > 0 ) {
      if ( everyOpenDayConfigurated ) {
        setAllCompleted(true);
      } else {
        setAllCompleted(false);
      }
    } else {
      setAllCompleted(false);
    }
  }, [everyOpenDayConfigurated, openDays, weekDays]);

  useEffect(() => {
    const newOpenDays = weekDays.filter((days) => days.open);
    if (newOpenDays !== openDays) {
      setOpenDays(newOpenDays);
    }
  }, [weekDays]);

  useEffect(() => {
    let openDays = weekDays.filter((days) => days.open);
    setEveryOpenDayConfigurated(
      openDays.every((openDay) => openDay.openingHour && openDay.closingHour)
    );
  }, [weekDays]);

  const handleStartDate = (date, weekDay) => {
    let singleDayStartDate = [];
    let errorRecordsCopy = [...timeErrors];
    if (new Date().setHours(weekDay?.closingHour, weekDay?.closingMinute, 0) < date) {
      singleDayStartDate = weekDays.map((day) => {
        let auxDay = day;
        if (day.day === weekDay.day) {
          return {
            ...auxDay,
            openingHour: 0,
            openingMinute: 0,
          };
        }
        return auxDay;
      });
    } else {
      singleDayStartDate = weekDays.map((day) => {
        let auxDay = day;
        if (day.day === weekDay.day) {
          return {
            ...auxDay,
            openingHour: date.getHours(),
            openingMinute: date.getMinutes(),
          };
        }
        let positionInErrorsRecord = errorRecordsCopy.findIndex((record) => record === weekDay.day);
        if (positionInErrorsRecord > -1) {
          errorRecordsCopy.splice(positionInErrorsRecord, 1);
          setTimeErrors([...errorRecordsCopy]);
        }
        return auxDay;
      });
    }
    if (weekDay?.closingHour === 0 && weekDay?.closingMinute === 0) {
      singleDayStartDate = weekDays.map((day) => {
        let auxDay = day;
        if (day.day === weekDay.day) {
          return {
            ...auxDay,
            openingHour: date.getHours(),
            openingMinute: date.getMinutes(),
          };
        }
        let positionInErrorsRecord = errorRecordsCopy.findIndex((record) => record === weekDay.day);
        if (positionInErrorsRecord > -1) {
          errorRecordsCopy.splice(positionInErrorsRecord, 1);
          setTimeErrors([...errorRecordsCopy]);
        }
        return auxDay;
      });
    }

    setWeekDays(singleDayStartDate);
  };

  const handleEndDate = (date, weekDay) => {
    let singleDayEndDate = [];
    let errorRecordsCopy = [...timeErrors];
    if (new Date().setHours(weekDay?.openingHour, weekDay?.openingMinute, 0) > date) {
      singleDayEndDate = weekDays.map((day) => {
        let auxDay = day;
        if (day.day === weekDay.day) {
          return { ...auxDay, closingHour: 0, closingMinute: 0 };
        }
        setTimeErrors([...timeErrors, weekDay.day]);
        return auxDay;
      });
    } else {
      singleDayEndDate = weekDays.map((day) => {
        let auxDay = day;
        if (day.day === weekDay.day) {
          return {
            ...auxDay,
            closingHour: date.getHours(),
            closingMinute: date.getMinutes(),
          };
        }
        let positionInErrorsRecord = errorRecordsCopy.findIndex((record) => record === weekDay.day);
        if (positionInErrorsRecord > -1) {
          errorRecordsCopy.splice(positionInErrorsRecord, 1);
          setTimeErrors([...errorRecordsCopy]);
        }
        return auxDay;
      });
    }
    setWeekDays(singleDayEndDate);
  };


  const handleAvailableDays = (e, selectedDay) => {
    e.stopPropagation();
    let weekDaysCopy = deepCopy(weekDays);
    let newWeekdays = weekDaysCopy.map((day) => {
      let auxDay = day;
      if (day.day === selectedDay.day) {
        auxDay = { ...auxDay, open: !auxDay.open };
        if (auxDay.open === false) {
          auxDay = {
            ...auxDay,
            openingHour: 0,
            openingMinute: 0,
            closingHour: 0,
            closingMinute: 0,
          };
        }
        return auxDay;
      }
      return auxDay;
    });
    setWeekDays(newWeekdays);
  };

  return {
    handleStartDate, handleEndDate,
    startDate, endDate,
    handleAvailableDays,
    everyOpenDayConfigurated,
    weekDays, timeErrors, openDays, 
    allCompleted
  }
}


const useMap = (cache, setIsLoading, body, setBody) => {
  const [defaultCoordinates, setDefaultCoordinates] = useState({ lat: 0, lng: 0 });
  const [locationInitialCoordinates, setLocationInitialCoordinates] = useState({});

  useEffect(() => {
    const { address, coordinates } = body
    if (address && coordinates) {
      const city = getCityName(address)
      setBody({ ...body, city: city })

    }
  }, [body.address, body.coordinates])

  const handleInitialCoordinates = () => {
    setIsLoading(true)
    if (cache?.latitude && cache?.longitude) {
      setDefaultCoordinates({
        lat: parseFloat(cache?.latitude),
        lng: parseFloat(cache?.longitude)
      })
      setLocationInitialCoordinates({
        lat: parseFloat(cache?.latitude),
        lng: parseFloat(cache?.longitude)
      })
      setIsLoading(false)
    } else {
      let defaultCoordByCountry = getCountryCoords("Argentina");
      setDefaultCoordinates({
        lat: parseFloat(defaultCoordByCountry?.lat),
        lng: parseFloat(defaultCoordByCountry?.lng)
      })
      setLocationInitialCoordinates({
        lat: parseFloat(defaultCoordByCountry?.lat),
        lng: parseFloat(defaultCoordByCountry?.lng)
      })
      setIsLoading(false)
    }
  }
  return { handleInitialCoordinates, locationInitialCoordinates, defaultCoordinates };
}





const useChange = (body, setBody, setPreparationTime, setShowWarning, setIsCustomTime) => {
  const handlePreparationChange = (e, custom = false) => {
    const { value } = e.target;
    console.log(value);
    const isCustomOverLimit = (value > 4 && custom)
    setShowWarning(isCustomOverLimit)
    setPreparationTime(Number(value) || null);
    setIsCustomTime(custom);
  };

  const onChange = (e) => {
    const { id, value } = e.target
    setBody({ ...body, [id]: value });
  }


  return {
    onChange, handlePreparationChange
  }
};