import React from 'react'
import { InputsSwitch } from '../shared/UI';
import { FiInfo } from 'react-icons/fi'
export const AutomaticSetter = ({ isAutomatic, setIsAutomatic, htmlProps }) => {
  const {title,subtitle, id, isInfo=false, link, txtLink} = htmlProps
  return (
    <fieldset className='d-flex flex-row align-items-center my-2 automaticSetter justify-content-between' id={id}>
      <div className="d-flex flex-column justify-content-start align-items-start">
        <h3>{title || ''}</h3>
        <div className="d-flex flex-row align-items-center">
          <span className={isInfo && 'icon-automaticSetter my-4'}>{isInfo && <FiInfo/>}</span>
          <p className={isInfo && 'm-0'}>{subtitle}</p>
        </div>
      </div>
      {setIsAutomatic &&
        <InputsSwitch
          htmlProps={{
            id: 'isAutomatic',
            extraClassName: 'rounded mx-3',
            value: isAutomatic,
            name: 'Tiempo de preparación',
          }}
          functionalProps={{
            checked: isAutomatic,
            onChange: () => setIsAutomatic(!isAutomatic),
          }}
        />}
    </fieldset>
  )
}
