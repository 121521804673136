
module.exports = {
  // Preorder
  "CREATED": {
    "color": "F52F41",
    "icon": "check",
    "text": "Pendiente",
    "tab": "pending",
    "secondaryActions": {},
    "primaryActions": [
      {
        "text": "Solicitar repartidor",
        "id": "request-rider",
      }
    ]
  },

  // Preorder
  "PREORDER": {
    "color": "F52F41",
    "icon": "check",
    "text": "Pendiente",
    "tab": "pending",
    "secondaryActions": {},
    "primaryActions": [
      {
        "text": "Solicitar repartidor",
        "id": "request-rider",
      }
    ]
  },


  // Cancelled
  "CANCELLED": {
    "color": "F52F41",
    "icon": "check",
    "text": "Cancelado",
    "tooltip": "El pedido fue cancelado",
    "tab": "error",
    "secondaryActions": {
      help: true,
    },
    "primaryActions": [
      {
        "text": "Volver a solicitar",
        "id": "retry"
      }
    ]
  },


  // In Progress
  "IN_PROGRESS": {
    "color": "000000",
    "icon": "check",
    "text": "En camino",
    "tooltip": "En camino a la dirección de retiro",
    "tab": "pending",
    "XD-text": "En depósito",
    "XD-tooltip": "Listo para entregar",
    "secondaryActions": {
      help: true,
      tracking: true
    },
    "primaryActions": []
  },

  // Confirmed
  "CONFIRMED": {
    "color": "000000",
    "icon": "check",
    "text": "Confirmado",
    "tooltip": "Listo para retirar",
    "tab": "pending",
    "XD-secondaryActions": {
      label: true,
    },
    "secondaryActions": {
      tracking: true,
      help: true,
    },
    "primaryActions": [{
      "text": "Cancelar",
      "id": "cancel"
    }]
  },

  // Ready for dispatched
  "READY_FOR_DISPATCH": {
    "color": "000000",
    "icon": "check",
    "text": "Confirmado",
    "tooltip": "Listo para retirar",
    "tab": "confirmed",
    "XD-text": "En depósito",
    "XD-tooltip": "Listo para entregar",
    "secondaryActions": {
      tracking: true,
      help: true,
    },
    "primaryActions": [{
      "text": "Cancelar",
      "id": "cancel"
    }]
  },

  "NEAR_PICKUP": {
    "color": "000000",
    "icon": "check",
    "text": "Por retirar",
    "tooltip": "El repartidor está llegando a retirar",
    "tab": "pending",
    "XD-text": "En depósito",
    "XD-tooltip": "Listo para entregar",
    "secondaryActions": {
      help: true,
      tracking: true
    },
    "primaryActions": []
  },

  // Confirmed
  "COMPLETED": {
    "color": "000000",
    "icon": "check",
    "text": "Entregado",
    "tab": "delivered",
    "secondaryActions": {
      print: true,
      proofOfDelivery: true,
    },
    "primaryActions": []
  },

  "PICKED_UP": {
    "color": "F52F41",
    "icon": "check",
    "text": "Por entregar",
    "tooltip": "En camino a la dirección de entrega",
    "tab": "confirmed",
    "secondaryActions": {
      help: true,
      tracking: true
    },
    "primaryActions": []
  },

  "EXPIRED": {
    "color": "F52F41",
    "icon": "check",
    "text": "Expirado",
    "tooltip": "El pedido expiró",
    "tab": "confirmed",
    "secondaryActions": {
      help: true,
    },
    "primaryActions": []
  },

  "NEAR_DROPOFF": {
    "color": "F52F41",
    "icon": "check",
    "text": "Por llegar",
    "tooltip": "El repartidor está llegando a entregar",
    "tab": "confirmed",
    "secondaryActions": {
      help: true,
      tracking: true
    },
    "primaryActions": []
  },
}