import React from 'react';
import { usePanelStore } from '../../../../store';

// Components
import Columns from "./Columns"
import Order from "./Order"


const Table = () => {
  const {
    filteredOrders,
    resultsPerPage,
    activePage,
  } = usePanelStore(state => state);

  return (
    <div id="table" className="orders-wrapper">
      <div className="orders-wrapper__header">
        <Columns />
      </div>
      <div className="orders-wrapper__body">
        {filteredOrders.map((order, index) => {
          if (
            index >= (activePage - 1) * resultsPerPage &&
            index < activePage * resultsPerPage
          )
            return <Order i={index} key={index} order={order} />
        })}
        {
          filteredOrders.length === 0 &&
          <div className="empty-orders">
            No hay órdenes para mostrar
          </div>
        }
      </div>
    </div>
  );
}

export default Table;
