import { devtools, persist } from 'zustand/middleware';
import panelInitialState from "./initialState/panel";
import moment from 'moment';
import statusSettings from '../config/statusSettings';

export let panelStore = set => ({
    ...panelInitialState,

    setPanelStore: (newState) => {
        set(state => ({
            ...state,
            ...newState
        }));
    },

    // Set Orders Array
    setOrders: (orders, noActivePage) => {
        set(state => {
            const filteredOrders = handleFilter(state, orders);
            return ({
                ...state,
                orders: orders,
                filteredOrders: filteredOrders,
                activePage: noActivePage ? state.activePage : 1,
                totalPages: Math.ceil(filteredOrders.length / state.resultsPerPage)
            })
        });
    },

    // Set Filtered Orders Array
    setFilteredOrders: (filteredOrders) => {
        set(state => ({
            ...state,
            filteredOrders: filteredOrders
        }));
    },

    // Set Sidebar Collapsed
    setSidebarCollapsed: (sidebarCollapsed) => {
        set(state => ({
            ...state,
            sidebarCollapsed: sidebarCollapsed
        }));
    },

    // Set Panel Active View
    setActiveTab: (activeTab) => {
        set(state => ({
            ...state,
            activeTab: activeTab,
        }));
    },

    // Set Active Tab at Orders View
    setOrdersActiveTab: (ordersActiveTab) => {
        set(state => {
            const filteredOrders = handleFilter({ ...state, ordersActiveTab }, state.orders);
            return ({
                ...state,
                ordersActiveTab: ordersActiveTab,
                filteredOrders: filteredOrders,
                activePage: 1,
                totalPages: Math.ceil(filteredOrders.length / state.resultsPerPage)
            })
        });
    },

    // Set Active Tab at Config View
    setActiveConfigTab: (activeConfigTab) => {
        set(state => ({
            ...state,
            activeConfigTab: activeConfigTab
        }));
    },

    // Set results for page at Orders Table
    setResultsPerPage: (resultsPerPage) => {
        set(state => ({
            ...state,
            resultsPerPage: resultsPerPage,
            totalPages: Math.ceil(state.filteredOrders.length / resultsPerPage),
            activePage: 1
        }));
    },

    // Set start date for date range filter at Orders Table
    setStartDate: (startDate) => {
        set(state => {
            const filteredOrders = handleFilter(state, state.orders);
            return ({
                ...state,
                startDate: startDate,
                filteredOrders: filteredOrders,
                totalPages: Math.ceil(filteredOrders.length / state.resultsPerPage),
                activePage: 1
            })
        });
    },

    // Set end date for date range filter at Orders Table
    setEndDate: (endDate) => {
        set(state => {
            const filteredOrders = handleFilter({ ...state, endDate }, state.orders);
            return ({
                ...state,
                endDate: endDate,
                filteredOrders: filteredOrders,
                totalPages: Math.ceil(filteredOrders.length / state.resultsPerPage),
                activePage: 1
            })
        });
    },

    // Set active page at Orders Table
    setActivePage: (activePage) => {
        set(state => ({
            ...state,
            activePage: activePage
        }));
    },

    setSearch: (search) => {
        set(state => {
            const filteredOrders =
                handleFilter({ ...state, search }, state.orders);
            return ({
                ...state,
                search: search,
                filteredOrders: filteredOrders,
                activePage: 1,
                totalPages: Math.ceil(filteredOrders.length / state.resultsPerPage)
            })
        });
    },

    selectOrder: (orderId) => {
        set(state => {
            const selectedOrder = state.orders.findIndex(order => order.orderId === orderId);
            const newOrders = [...state.orders];
            newOrders[selectedOrder].selected = !newOrders[selectedOrder].selected;
            const filteredOrders = handleFilter(state, newOrders);
            return ({
                ...state,
                orders: newOrders,
                filteredOrders: filteredOrders,
                totalPages: Math.ceil(filteredOrders.length / state.resultsPerPage),
                activePage: 1
            })
        });
    },

    // Set selected orders at Orders Table
    selectAllOrders: (selected) => {
        set(state => {
            const newOrders = [...state.orders];
            newOrders.forEach(order => order.selected = selected);
            const filteredOrders = handleFilter(state, newOrders);
            return ({
                ...state,
                orders: newOrders,
                filteredOrders: filteredOrders,
                totalPages: Math.ceil(filteredOrders.length / state.resultsPerPage),
                activePage: 1,
                selectAllOrdersStatus: selected
            })
        });
    },

});


// Handle Filter
const handleFilter = (state, orders) => {
    const { startDate, search, ordersActiveTab } = state
    let { endDate } = state
    const parsedSearch = search.toLowerCase().trim()
    console.log(endDate, startDate)

    if (endDate)
        endDate = moment(endDate).add("23", "hours").add("59", "minutes").add("59", "seconds")

    const matchString = (string) => {
        const newString = `${string}`.toLowerCase().trim();
        return newString.includes(parsedSearch);
    }

    let filteredOrders = []

    // Filter Date Range
    for (const order of orders) {
        let included = false

        // Filter Search
        if (parsedSearch) {
            if (
                matchString(order.orderNumber) ||
                // matchString(order.shippingAddress.firstName) ||
                // matchString(order.shippingAddress.lastName) ||
                matchString(order.orderId)
            ) {
                filteredOrders.push(order)
            } else {
                continue
            }
        } else {
            included = true
        }

        // Filter Date Range
        if (startDate || endDate) {
            const orderDate = moment(order.dateCreated)
            console.log(orderDate)
            if (startDate && endDate) {
                // Check if start and end date are the same
                if (moment(startDate).isSame(moment(endDate))) {
                    const newEndDate = moment(endDate).add("23", "hours").add("59", "minutes").add("59", "seconds")
                    if (orderDate.isBetween(startDate, newEndDate)) {
                        included = true
                    } else {
                        included = false
                    }
                } else if (orderDate.isBetween(startDate, endDate)) {
                    included = true
                } else {
                    included = false
                }
            } else if (startDate) {
                if (orderDate.isSameOrAfter(startDate)) {
                    included = true
                } else {
                    included = false
                }
            } else if (endDate) {
                if (orderDate.isSameOrBefore(endDate)) {
                    included = true
                } else {
                    included = false
                }
            }
        } else {
            included = true
        }

        const mappedTab = statusSettings[order.trackingStatus]
        console.log(mappedTab)
        console.log(ordersActiveTab)
        if (mappedTab?.tab !== ordersActiveTab && ordersActiveTab !== 'all') {
            included = false
        }

        const alreadyIncluded = filteredOrders.find(o => (o.orderId === order.orderId && o.orderNumber === order.orderNumber))
        if (included && !alreadyIncluded)
            filteredOrders.push(order)
    }
    return filteredOrders
}


// panelStore = persist(panelStore, { name: 'general' });
panelStore = devtools(panelStore);