import React, { useEffect, useState } from 'react';
import { getOrders } from '../../../../services/panel.service';
import DatePicker from "react-datepicker";

// Elements
import { Input } from "react-package"
import { FadeIn } from 'react-package';
import DateCustomInput from "../../../../components/DateCustomInput"
// Icons
import { BsSearch } from 'react-icons/bs'
import { FiRefreshCcw } from 'react-icons/fi'
import { useGeneralStore, usePanelStore } from '../../../../store';


const Filter = () => {
  const { startDate, setStartDate, endDate, setEndDate, search, setSearch, setOrders } =
    usePanelStore(state => state);
  const [innerSearch, setInnerSearch] = useState("");

  const { appId, store, token } = useGeneralStore(state => state);
  const color = useGeneralStore(state => state.settings.colors.primary);

  const onChange = (dates) => {
    const [start, end] = dates;
    setStartDate(start);
    setEndDate(end);
  };

  const handleRefetch = async () => {
    const newOrders = await getOrders(store, token);
    if (newOrders && newOrders?.data)
      setOrders(newOrders.data);
  }

  useEffect(() => {
    setSearch(innerSearch);
  }, [innerSearch]);

  return (
    <div className="filter-wrapper">
      <FadeIn delay={1} className="filter-wrapper__left">
        <Input
          placeholder="Buscar órdenes"
          RightIcon={BsSearch}
          value={innerSearch}
          id="search-orders"
          onChange={(e) => setInnerSearch(e.target.value)}
          variants="sm"
        />
        <DatePicker
          selected={startDate}
          onChange={onChange}
          startDate={startDate}
          endDate={endDate}
          selectsRange
          dateFormat={'dd/MM/yyyy'}
          isClearable
          id="date-range-filter"
          placeholderText="Fecha de creación"
          clearButtonClassName='clear-button'
          customInput={<DateCustomInput />}
        />
      </FadeIn>
      <div className="filter-wrapper__right">
        <div onClick={() => handleRefetch()} className="refetch-button">
          <FiRefreshCcw />
        </div>
      </div>
    </div>
  );
}

export default Filter;
