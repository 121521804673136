import React from 'react'
import LogoPeya3x3 from '../../assets/peya3x3.svg'
import LogoPeya3x2 from '../../assets/peya3x2.svg'
import { motion } from 'framer-motion'
import { peyaCard } from '../../services/shared/framermotion.service'

export const Peya3x3 = () => {
    return (
    <motion.aside key='logo-container-3'id='peya3x3' {...peyaCard}>
        <img src={LogoPeya3x3} alt="Logo de Pedidos ya" />
    </motion.aside>
    )
}
export const Peya3x2 = () => {
    return (
    <motion.aside key='logo-container-2'id='peya3x2'>
        <img src={LogoPeya3x2} alt="Logo de Pedidos ya" />
    </motion.aside>
    )
}
