const general = {
    notification: {
        type: "",
        message: "",
        visible: false
    }
}


const initialState = {
    general
}

export default initialState