import React, { useMemo, useState } from 'react'
import { GoogleMap, useLoadScript, Marker } from '@react-google-maps/api';

import "@reach/combobox/styles.css";
import { MoonLoader } from 'react-spinners';
import { CustomAutoComplete } from './AutoComplete';

const secretKey = process.env.REACT_APP_PLACES_API_KEY
const libraries = ['places']
export const MapContainer = ({ 
    body,
    setBody, 
    centerCoordinates, 
    locationInitialCoordinates,
    tabIndex
}) => {
    const { isLoaded } = useLoadScript({
        googleMapsApiKey: secretKey,
        libraries: libraries,
    });
    if(!isLoaded) return <MoonLoader color='#FA0050'/>;
    return (
        <Map 
        body={body}
        setBody={setBody}
        centerCoordinates={centerCoordinates} 
        initialMarker={locationInitialCoordinates} 
        tabIndex={tabIndex}
        />
    )
}

export const Map = ({ body, setBody, centerCoordinates, initialMarker, tabIndex }) => {
    const center = useMemo(() => (centerCoordinates), []);
    const [selected, setSelected] = useState(initialMarker);

    return(
        <>
            <div className="places-container">
                <CustomAutoComplete 
                setSelected={setSelected} 
                setBody={setBody}
                body={body}
                tabIndex={tabIndex}
                />
            </div>

            <GoogleMap
            zoom={body.address ? 18 : 14}
            center={selected ? selected : center}
            mapContainerClassName="map-container"
            >
                {(selected) && <Marker position={selected} />}
            </GoogleMap>
        </>
    )
}
