import { post as restPost } from "./shared/rest.service";

export const postStore = async(userId,body) =>{
  try {
    const endpoint = `onboarding/store`;
    const params= { userId: userId }
    const result = await restPost( endpoint, body, params );
    return result.data;
  } catch (error) {
    return error
  }
}
export const sameDayShipProps = {
  htmlProps: {
    label: 'Envíos en el día',
    sublabel: 'Podrás mostrar dos horarios distintos para entregar en el mismo día o el día siguiente.',
    id: 'sameDayShip',
    colSize:'col-12 mt-4 mt-xxl-5',
    margins: 'mb-2 mx-4',
  }
}
export const immediateShipProps = {
  htmlProps: {
    label: 'Envíos inmediatos',
    sublabel: 'Para entregas rápidas, en menos de una hora, podrás activar esta opción para tu tienda.',
    id: 'immediateShip',
    colSize:'col-12',
    margins: 'mb-2 mx-4',
  }
}


export const timeOptions = [
  {
    label: "30 min",
    id: 'immediateship',
    value: 30
  },
  {
    label: "1:00 h",
    id: 'immediateship',
    value: 60
  },
  {
    label: "2:00 h",
    id: 'immediateship',
    value: 120
  },
  {
    label: "3:00 h",
    id: 'immediateship',
    value: 180
  },
  {
    label: "1 día",
    id: 'immediateship',
    value: 1440
  },
]
export const deliveryDelayProps = {
  htmlProps: {
    label: '1hs',
    id: 'immediateship',
    colSize:'col-1',
    name:'delivery'
  }
}


export const additionalNotesProps = {
  functionalProps: {
    type: 'text'
  },
  htmlProps: {
    label: 'Información adicional',
    id: 'additionalNotes',
    colSize:'col-10',
    placeholder: 'Piso, timbre, casa, etc.',
    tabIndex: 2
  }
}


export const nameProps = {
  functionalProps: {
    type: 'text'
  },
  htmlProps: {
    label: 'Por quién preguntamos*',
    id: 'managerName',
    colSize: 'col-5',
    placeholder: 'Por María, por el área contable, etc.',
    tabIndex: 4
  }
}

export const phoneProps = {
  functionalProps: {
    type: 'text'
  },
  htmlProps: {
    label: 'Teléfono',
    id: 'phoneNumber',
    colSize: 'col-10',
    placeholder: '11563564524',
    margins: 'mx-4 mb-2',
    tabIndex: 3
  }
}

export const notesProps = {
  functionalProps: {
    type: 'text'
  },
  htmlProps: {
    label: 'Instrucciones para el retiro',
    id: 'instructions',
    colSize:'col-5',
    placeholder: 'Tocar timbre dos veces...',
    tabIndex: 5
  }
}

export const getCityName = (fullName='streetName,cityName,countryName') => {
  const strings =  fullName.split(',')
  return strings[1]
}