import React from 'react'
import DatePicker from 'react-datepicker'
import { comeFromLeftAn, dayPickAn } from '../../services/shared/framermotion.service'
import { motion, AnimatePresence } from 'framer-motion'
export const HoursSchedule = ({
  day,
  handleStartDate,
  handleEndDate,
  startDate,
  endDate,
  timeErrors,
  index='hoursschedule' }) => {
  const formatDatePick = (type) => {
    if ( type==='close' ){
      return day.closingHour !== 0 ? new Date().setHours(day?.closingHour, day?.closingMinute, 0) : endDate
    }
    if ( type==='open'){
      return day.openingHour !== 0 ? new Date().setHours(day?.openingHour, day?.openingMinute, 0) : startDate
    }
  }
  return (
    <motion.div className='week-hours-container' {...dayPickAn} key={`week-hours-container-${index}`} >
      <div className='personalized-week-hours-wrapper'>
        <DatePicker
          selected={formatDatePick('open')}
          onChange={(date) => handleStartDate(date, day)}
          showTimeSelect
          showTimeSelectOnly
          timeIntervals={30}
          timeCaption="Time"
          dateFormat="hh:mm aaaa"
          className="custom-picker-input"
          wrapperClassName="custom-picker-wrapper"
          placeholderText="9:00 a.m."
        />
        <p>a</p>
        <DatePicker
          selected={formatDatePick('close')}
          onChange={(date) => handleEndDate(date, day)}
          showTimeSelect
          showTimeSelectOnly
          timeIntervals={30}
          timeCaption="Time"
          dateFormat="hh:mm aaaa"
          className="custom-picker-input"
          wrapperClassName="custom-picker-wrapper"
          placeholderText="18:00 p.m."
        />
        <AnimatePresence>
          {(timeErrors.length > 0 && timeErrors.some(record => record === day.day)) && 
            <motion.p key='invalid-time' className='invalid-msg' {...comeFromLeftAn}>El horario es inválido.</motion.p>
          }
        </AnimatePresence>
      </div>
    </motion.div>
  )
}
