const initialState = {
    store: "",
    token: "",
    appId: "",
    appName: "",
    settings: {
        colors: {
            primary: "",
            secondary: "",
        },
        onboarding: {
            crendentials: [],
            fields: {
                createAt: {
                    panel: false,
                    created: false,
                    paid: false,
                    packed: false,
                }
            }
        }
    },
    storeName: "",
    storeUrl: "",
    countries: [],
    darkmode: false,
}

export default initialState