import { devtools, persist } from 'zustand/middleware';
import generalInitialState from "./initialState/general";

export let generalStore = set => ({
    ...generalInitialState,

    setGeneralStore: (newState) => {
        set(state => ({
            ...state,
            ...newState
        }));
    },

    setDarkmode: (darkmode) => {
        set(state => ({
            ...state,
            darkmode
        }));
    },

});

// generalStore = persist(generalStore, { name: 'general' });
generalStore = devtools(generalStore);