
//Icons
import { BsBox } from 'react-icons/bs'
import { BiPieChart } from 'react-icons/bi';

export const tabs = [
  // {
  //   id: "stats",
  //   icon: BiPieChart,
  //   label: "Estadísticas",
  //   iconSize: "1.5",
  // },
  {
    id: "orders",
    label: "Órdenes",
    icon: BsBox,
    iconSize: "1.4",
    roles: ["admin"]
  }
]