import React from 'react'
import { motion } from 'framer-motion'
import City from '../assets/city.png'
import { WelcomeMessage } from './../components/Welcome/';
import { Peya3x3 } from './../components/shared/LogoContainer';
import { buttonWelcome, leaveWelcomeImage, movePage, welcomeBar } from '../services/shared/framermotion.service';
import { useWelcome } from './../services/hooks/useWelcome';
import { SpinnerPeya } from './../components/shared/UI';
import { AnimatePresence } from 'framer-motion';
import { leaveSlow } from './../services/shared/framermotion.service';

import LoaderGif from '../assets/loading.svg'

export const Welcome = () => {
    const { isLoading, onContinue, animateLeave } = useWelcome()
    
    const conditionalSpinner = () => {
        if ( isLoading ) {
            return <SpinnerPeya {...leaveSlow} />
        }
    }
    
    return (
        <motion.main key='main-welcome-container' {...movePage}>
            <AnimatePresence>
                {conditionalSpinner()}
                    <motion.section id='welcome-container' {...welcomeBar}>
                        <div className="row ">
                            <div className="col-6">
                                <motion.img key='welcome-img' variants={leaveWelcomeImage} animate={animateLeave && 'leave'} src={City} alt="Repartidor en ciudad" id="city-art" />
                            </div>
                            <div className="col-6" id='welcome-right'>
                                <Peya3x3 />
                                <WelcomeMessage />
                                <section className="d-flex flex-row">
                                    <a href="https://envios.pedidosya.com.ar/registry" rel="noopener" target='_blank'>
                                        <motion.button
                                            key='welcome--button'
                                            className='fw-bold px-4'
                                            {...buttonWelcome}
                                        >
                                            Registrarme gratis en PedidosYa Envíos
                                        </motion.button>
                                    </a>
                                    <motion.button
                                        key='back--button'
                                        className='btn-alternative ms-4'
                                        onClick={onContinue}
                                        disabled={isLoading }
                                        {...buttonWelcome}
                                    >
                                        { !isLoading ? 'Ya estoy registrado' : <img src={LoaderGif} alt="Loading spinner" />}
                                    </motion.button>
                                </section>
                            </div>
                        </div>
                    </motion.section>
            </AnimatePresence>
        </motion.main>
    )
}
