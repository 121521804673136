import axios from 'axios';
import CryptoJS from 'crypto-js';

const { REACT_APP_BASE_URL:baseUrl, REACT_APP_CRYPTOJS_SECRET_KEY } = process.env;
// const baseUrl = REACT_APP_BASE_URL;
// const baseUrl = 'https://localhost:3000/';
// const baseUrl = 'https://pedidosya-api-stage.conexa.ai/';

const encryptBody = (body) => {
  return CryptoJS.AES.encrypt(
    JSON.stringify({
      data: body,
      secretKey: REACT_APP_CRYPTOJS_SECRET_KEY,
      timestamp: new Date().getTime(),
    }),
    REACT_APP_CRYPTOJS_SECRET_KEY
  ).toString();
};

const encryptToken = () => {
  return CryptoJS.AES.encrypt(
    JSON.stringify({
      secretKey: REACT_APP_CRYPTOJS_SECRET_KEY,
      timestamp: new Date().getTime(),
    }),
    REACT_APP_CRYPTOJS_SECRET_KEY
  ).toString();
};

export const post = async (endpoint, body, params, headers) => {
  try {
    const encryptedToken = encryptToken();
    const url = `${baseUrl}api/v2${endpoint}`;
    const result = await axios({
      method: 'POST',
      url,
      params: params,
      data: body,
      headers: {
        ...headers,
        'Content-Type': 'application/json',
        Authorization: encryptedToken,
      },
    });
    return result;
  } catch (error) {
    const message = (await error?.response?.data?.error) || 'Ocurrió un error en la conexión';
    throw new Error(message);
  }
};

export const get = async (endpoint, params, headers) => {
  try {
    const encryptedToken = encryptToken();
    const url = `${baseUrl}api/v2${endpoint}`;
    const result = await axios({
      method: 'GET',
      params: params,
      url,
      headers: {
        ...headers,
        'Content-Type': 'application/json',
        Authorization: encryptedToken,
      },
    });
    return result;
  } catch (error) {
    console.log(error);
    const message = (await error.response.data.error) || 'Ocurrió un error en la conexión';
    throw new Error(message);
  }
};
