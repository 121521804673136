import React from 'react'
import { timeOptions } from '../../services/store.service';
import { InputsRadioAsButton } from '../shared/UI';
import { useState } from 'react';
import { AnimatePresence } from 'framer-motion';
import { customTimeAn, preparationError } from '../../services/shared/framermotion.service';
import { motion } from 'framer-motion';

export const PreparationInMinutes = ({ 
  onChange, 
  actualValue, 
  isCustomTime, 
  showWarning 
}) => {
  const [showCustomTime,setShowCustomTime] = useState(isCustomTime)
  const handleChange = (e,isOther) =>{
    setShowCustomTime(isOther)
    onChange(e,isOther)
  }

  return (
    <fieldset className='d-flex flex-row justify-content-between preparation-in-minutes-container'>
      {timeOptions.map((option, index) => (
        <InputsRadioAsButton
          htmlProps={{
            label: option.label,
            id: `time ${index}`,
            tabIndex: 3+index,
            value: option.value,
            extraClassName: 'radio-button-days',
            name: 'Tiempo de preparación',
          }}
          functionalProps={{
            checked: actualValue===option.value,
            onChange: (e)=>handleChange(e,false),
            isDisabled: false,
          }}
          key={`time ${index}`}
        />
      ))}
      <div className="d-flex flex-row other-container">
        <InputsRadioAsButton
          htmlProps={{
            label: 'Otro',
            id: 'other',
            tabIndex: `timeCustom 1}`,
            extraClassName: 'col-2 radio-button-days',
            name: 'Tiempo de preparación',
          }}
          functionalProps={{
            checked: isCustomTime===true,
            onChange: (e)=>handleChange(e,true),
            isDisabled: false,
          }}
          isCustom={true}
          key={`timeCustom`}
        />
        <AnimatePresence>
          <label className='radioBtn-custom' {...customTimeAn} key='minutes-label'>
            <input
              type="number"
              className="form-control"
              id="customTime"
              placeholder="Ingresar"
              onChange={(e)=>onChange(e,true)}
              value={isCustomTime === true ? actualValue : null}
              pattern="[0-9]"
              step="1"
              disabled={false}
            />
          </label>
          {showWarning && 
            <motion.span {...preparationError} key='minutes-label' className='limit-preparation txt-alert fw-bold'>El límite es de 4 días.</motion.span>
          }
        </AnimatePresence>
      </div>
    </fieldset>
  )
}
