import React from 'react';
import { tabs } from "../../../config/tabs"

// Icons
import { usePanelStore } from '../../../store';

// Components
import { Sidebar } from "react-package"

const SidebarWrapper = () => {
  const { activeTab, setActiveTab } = usePanelStore(state => state);

  return (
    <Sidebar
      activeTab={activeTab}
      tabs={tabs}
      onClick={(id) => setActiveTab(id)}
      primaryColor="#F52F41"
    />
  );
}

export default SidebarWrapper;
