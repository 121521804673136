import React from 'react'
import Logo3x2 from '../../assets/peya3x2.svg'
import { motion } from 'framer-motion';
import { useNav } from '../../services/hooks/useNav';
import { Steps } from 'rsuite';
import { useLocation } from 'react-router';
import { BsCheck } from 'react-icons/bs';
import { useNavStore } from '../../store';
import { SyncLoader } from 'react-spinners';


export const StorePanel = () => {
  const { isLoading, styles, currentStatus, description } = useNav()
  const cacheStep = useNavStore((state) => state.step);

  if (cacheStep === 5) {
    return null;
  }

  if (isLoading) {
    return <SyncLoader color="#F544E" />;
  }

  return (
    <motion.nav id='store-panel' className='nav-panel'>
      <motion.img id='logo' src={Logo3x2} alt="Logo de pedidos ya" />
      <motion.section key='section-store-panel'>
        <Steps vertical style={styles} currentStatus={currentStatus}>
          {description.map(({ title, subtitle }, index) => {
            return <ListContent title={title} subtitle={subtitle} index={index + 1} />
          })}
        </Steps>
      </motion.section>
    </motion.nav>
  )
}

const ListContent = ({ title, subtitle, index }) => {
  const { Item } = Steps;
  const location = useLocation();
  const { pathname } = location;
  const baseRoute = '/pedidosya/tn/store';
  const routes = ['location', 'delivery', 'finish'];
  const cacheStep = useNavStore((state) => state.step); //This is a workaround

  const handleTitleStyle = (index) => {
    if (index < cacheStep) {
      return 'text-success fw-normal finished-item';
    }
    if (cacheStep === index) {
      return 'active-item';
    }
    return 'unactive-item';
  };

  return (
    <Item
      key={`description-${index}`}
      title={<span className={`title-item ${handleTitleStyle(index)}`}>{title}</span>}
      description={cacheStep === index && <span className="active-subtitle">{subtitle}</span>}
      icon={<ListIcon index={index} isActive={cacheStep === index} step={cacheStep} />}
    />
  );
};

const ListIcon = ({ index, isActive, step }) => {
  const handleContent = () => {
    if (index < step) {
      return <BsCheck fontSize={'1rem'} />;
    }
    if (index > step) {
      return '';
    }
    return index;
  };
  return (
    <li
      className={`store-list-item isActive-${isActive} isFinished-${index < step} list-style-none`}
    >
      {handleContent()}
    </li>
  );
};
