import { maxInputLength } from '../../data/InfoSource';

export const addZeroIfBelowTen = (number) => {
  return ('0' + number).slice(-2);
};

export const getRandomTransformOrigin = () => {
  const value = (12 + 38 * Math.random()) / 100;
  const value2 = (13 + 39 * Math.random()) / 100;
  return {
    originX: value,
    originY: value2,
  };
};

export const stopPropagation = (e) => {
  e.stopPropagation();
};

export function deepCopy(src) {
  let target = Array.isArray(src) ? [] : {};
  for (let key in src) {
    let v = src[key];
    if (v) {
      if (typeof v === 'object') {
        target[key] = deepCopy(v);
      } else {
        target[key] = v;
      }
    } else {
      target[key] = v;
    }
  }

  return target;
}

export const validateEmail = (email) => {
  const rgx =
    /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/g;
  return rgx.test(String(email).toLowerCase());
};

export const validatePhone = (phoneNumber) => {
  // ! THIS IS DUE PHONE NUMBER IS NOT MANDATORY
  if (!phoneNumber) {
    return true;
  }
  let rgx = /^\+?[1-9][0-9]\d{0,12}$/;
  if (phoneNumber[0] === '+') {
    rgx = /^\+?[1-9][0-9]\d{0,11}$/;
  }
  return rgx.test(phoneNumber);
};

export const validateLength = (inputData) => {
  if (!inputData) return false;
  if (String(inputData).length < 3) return false;
  if (limitLength(inputData)) return true;
};

export const limitLength = (string) => {
  if (!string) return true;
  if (String(string.length <= maxInputLength)) return true;
};

export const checkBiggerNum = (from, to) => {
  if (Number(from) <= Number(to)) return true;
};

export const checkPercentageLimits = (value) => {
  if (Number(value) >= -100 && Number(value) <= 100) return true;
};

export const validateFullAddress = (dataArr, failedMsg = '- Dirección incompleta -') => {
  const isMissingData = dataArr.some((geoInfo) => !geoInfo || geoInfo.toString().includes('null'));
  if (isMissingData) {
    return failedMsg;
  } else {
    return dataArr.join(', ');
  }
};
