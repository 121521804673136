import { post as restPost, get as restGet } from './shared/rest.service';
import { base64toBlob } from './utils.service';

const {
    REACT_APP_BASE_URL
} = process.env;

export const getInitialState = async (userId, token) => {
    console.log("@@@ Getting initialState: ")

    const endpoint = `/onboarding/initialState?userId=${userId}`;
    const result = await restGet(endpoint);
    return result.data;
}


export const getOrders = async (userId) => {
    console.log("@@@ Getting orders: ")

    const endpoint = `/panel/orders?userId=${userId}`
    const result = await restGet(endpoint);
    return result.data;
}

export const printLabels = (order) => {
    if (order.label) {
        const label = base64toBlob(order.label)
        const url = URL.createObjectURL(label);
        window.open(url);
    } else {
        window.open(`${REACT_APP_BASE_URL}api/v2/panel/label?orderId=${order.orderId}`);
    }
}

export const requestRider = async (orderId) => {
    console.log("@@@ Requesting rider: ", orderId)
    const endpoint = `/panel/shipment`
    const body = { orderId }
    const result = await restPost(endpoint, body);
    return result.data;
}

export const cancelOrder = async (orderId) => {
    console.log("@@@ Canceling order: ", orderId)

    const endpoint = `/panel/shipment/cancel`
    const body = { orderId }
    const result = await restPost(endpoint, body);
    return result.data;
}

export const payOrder = (orderId) => {
    console.log("@@@ Paying order: ", orderId)
}

export const primaryActionsMap = {
    "print": printLabels,
    "request-rider": requestRider,
    "retry": requestRider,
    "cancel": cancelOrder,
    "pay": payOrder
}