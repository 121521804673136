import React from 'react'
import { motion } from 'framer-motion'
import { welcomeMessageAn } from '../../services/shared/framermotion.service';
export const WelcomeMessage = () => {
  return (
    <motion.summary id='welcome-message' {...welcomeMessageAn}>
      <h2 className='fw-bold my-1 mt-xxl-2 mb-0'>Para instalar la aplicación, necesitás</h2>
      <h2 className='fw-bold my-1 mt-0'>registrar tu empresa en PedidosYa Envíos</h2>
      <h3 className='my-1 my-xxl-2'>Te ayudamos paso a paso y en minutos podrás ofrecer nuestros envíos a tus clientes.</h3>
    </motion.summary>
  )
}
