import React from "react";
import {  motion } from "framer-motion";
import {  InputsRadio, InputsCheck } from "../shared/UI";
import { useDelivery } from "../../services/hooks/useDelivery";
import { AutomaticSetter } from "./AutomaticSetter";
import { deliveryAn } from "../../services/shared/framermotion.service";
import LoaderGif from '../../assets/loading.svg'
import { FiInfo } from 'react-icons/fi';

export const Delivery = ({ stageChangeFn }) => {
  const { isLoading, isAutomatic, setIsAutomatic,
    handleRadioChange, deliveryType, onSubmit ,
    hourRange, handleCheckboxChange, areHoursSynced,
  } = useDelivery(false, stageChangeFn)

  return (
    <motion.section
      key='delivery-container'
      id="delivery-container"
      className="d-flex flex-column justify-content-start"
      {...deliveryAn}
    >
      <h2 className="fw-bold mb-0 mt-2 mt-xxl-4">Elegí qué envíos querés ofrecer en tu tienda</h2>
      <form onSubmit={onSubmit} className="d-flex flex-column mb-3 mb-xxl-4" id='form-delivery'>
        <motion.div className='mt-3 mt-xxl-4'>
          {areHoursSynced ? (
            <>
              <InputsRadio
                extraClassname='radio-margin-bottom'
                id="sameDayShip"
                name="deliveryType"
                value="sameDayShip"
                disabled={isLoading}
                labelTxt='Envíos inmediatos'
                descriptionTxt="Retiramos y entregamos el paquete a tu cliente lo antes posible."
                onChangeFn={handleRadioChange}
                checked={deliveryType === "sameDayShip"}
              />
              <InputsRadio
                id="hourRange"
                name="deliveryType"
                extraClassname='radio-margin-bottom margin-fixed-fee'
                value="hourRange"
                disabled={isLoading}
                labelTxt="Envíos por franja horaria (Más económico)"
                descriptionTxt={`Retiramos el paquete a las ${hourRange.entry} hs o a las ${hourRange.exit} hs para entregarlo a tu cliente en una franja horaria posterior. Estos envíos son más económicos para tus clientes.`}
                onChangeFn={handleRadioChange}
                checked={deliveryType === "hourRange"}
              />
              <InputsRadio
                id="mixed"
                name="deliveryType"
                value="mixed"
                disabled={isLoading}
                labelTxt="Ambos envíos (Recomendada)"
                descriptionTxt="Tus clientes podrán elegir cualquiera de las dos opciones de envío de arriba."
                onChangeFn={handleRadioChange}
                checked={deliveryType === "mixed"}
              />
            </>
          ) : (
            <>
              <InfoDesynced stageChangeFn={stageChangeFn} />
              <InputsCheck
                id="sameDayShip"
                name="deliveryType"
                value="sameDayShip"
                extraClassname="radio-margin-bottom margin-fixed-fee desynced-deliveryType"
                disabled={isLoading}
                labelTxt="Envíos inmediatos"
                descriptionTxt="Retiramos y entregamos el paquete a tu cliente lo antes posible."
                onChangeFn={handleCheckboxChange}
                checked={deliveryType === 'sameDayShip'}
              />
            </>
          )}
        </motion.div>

        <div className="automaticSetter-container ">
          <AutomaticSetter
            isAutomatic={isAutomatic}
            setIsAutomatic={setIsAutomatic}
            htmlProps={{
              id: 'automaticSetter',
              title: 'Solicitar repartidores automáticamente',
              subtitle: (
                <ul>
                  <li>
                    <strong>Si lo activás</strong>, asignaremos repartidores de
                    acuerdo a los envíos que soliciten tus clientes y al tiempo
                    que configuraste para la preparación de tus paquetes.
                  </li>
                  <li>
                    <strong>Si no lo activás</strong>, tendrás que solicitar
                    repartidores manualmente.
                  </li>
                </ul>
              ),
            }}
          />
        </div>
        <footer className='d-flex justify-content-end align-items-center'>
          <button type="button" onClick={() => stageChangeFn(2)} className='btn btn-outline-secondary px-5 me-5'>
            Volver
          </button>
          <button type="submit"
            className={`isDisabled-${isLoading} btn-onboarding finish`}
            disabled={isLoading}>
            { !isLoading ? 'Finalizar instalación' : <img src={LoaderGif} alt="Loading spinner" />}
          </button>
        </footer>
      </form>
    </motion.section>
  );
};

const InfoDesynced = ({ stageChangeFn }) => {
  return (
    <section className="desynced-message">
      <FiInfo size="1.25rem" />
      <article>
        Solo podrás ofrecer envíos inmediatos en los días y horarios de retiro
        que configuraste.
        <span onClick={() => stageChangeFn(2)}>Modificar configuración</span>
        {/* <p>
          Solo podrás ofrecer envíos inmediatos en los días y horarios de retiro que configuraste.
        </p>
        <div onClick={() => stageChangeFn(2)}>Modificar configuración</div>. */}
      </article>
    </section>
  );
};
