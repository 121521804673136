import { post as restPost } from "./shared/rest.service";
export const saveLocationsData = async (body, storeId) => {
  try {
    const endpoint = `/onboarding/shipment`;
    const params={ userId: storeId }
    const result = await restPost(endpoint,body, params);
    return result.data;
  } catch (error) {
    throw error.message;
  }
};


export const checkCoverage = async ( body, storeId ) => {
  try {
    const endpoint = `/onboarding/validateLocation`;
    const params = { userId: storeId };
    const result = await restPost ( endpoint, body, params);
    return result.data;
  } catch (error) {
    console.log(error);
    throw error.message;
  }
};

export const weekdaysTemplate = [
  {
    day: 0,
    open: false,
    openingHour: 9,
    openingMinute: 0,
    closingHour: 18,
    closingMinute: 0,
  },
  {
    day: 1,
    open: true,
    openingHour: 9,
    openingMinute: 0,
    closingHour: 18,
    closingMinute: 0,
  },
  {
    day: 2,
    open: true,
    openingHour: 9,
    openingMinute: 0,
    closingHour: 18,
    closingMinute: 0,
  },
  {
    day: 3,
    open: true,
    openingHour: 9,
    openingMinute: 0,
    closingHour: 18,
    closingMinute: 0,
  },
  {
    day: 4,
    open: true,
    openingHour: 9,
    openingMinute: 0,
    closingHour: 18,
    closingMinute: 0,
  },
  {
    day: 5,
    open: true,
    openingHour: 9,
    openingMinute: 0,
    closingHour: 18,
    closingMinute: 0,
  },
  {
    day: 6,
    open: false,
    openingHour: 9,
    openingMinute: 0,
    closingHour: 18,
    closingMinute: 0,
  },
];

export const dayLabels = ['Domingo', 'Lunes', 'Martes', 'Miércoles', 'Jueves', 'Viernes', 'Sábado'];

export const dayNames = ['Domingo', 'Lunes', 'Martes', 'Miércoles', 'Jueves', 'Viernes', 'Sábado'];

export const mockForm = {
  address: "Corrientes 1420",
  managerName: "Nombre Apellido",
  phoneNumber: "+5492216268412",
  instructions: "",
  preparationTime: 0, // En horas
  immediateShip: true,
  sameDayShip: true,
  automaticCreation: true,
  timeSchedule: [
    {
      day: 0,
      open: false,
      openingHour: 9,
      openingMinute: 0,
      closingHour: 18,
      closingMinute: 0,
    },
    {
      day: 1,
      open: false,
      openingHour: 9,
      openingMinute: 0,
      closingHour: 18,
      closingMinute: 0,
    },
    {
      day: 2,
      open: false,
      openingHour: 9,
      openingMinute: 0,
      closingHour: 18,
      closingMinute: 0,
    },
    {
      day: 3,
      open: false,
      openingHour: 9,
      openingMinute: 0,
      closingHour: 18,
      closingMinute: 0,
    },
    {
      day: 4,
      open: false,
      openingHour: 9,
      openingMinute: 0,
      closingHour: 18,
      closingMinute: 0,
    },
    {
      day: 5,
      open: false,
      openingHour: 9,
      openingMinute: 0,
      closingHour: 18,
      closingMinute: 0,
    },
    {
      day: 6,
      open: false,
      openingHour: 9,
      openingMinute: 0,
      closingHour: 18,
      closingMinute: 0,
    },
  ],
};
