import React from "react";
import { AnimatePresence } from "framer-motion";
import { Routes, Route, useLocation, BrowserRouter } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import "../App.scss";

import { Welcome } from '../pages/Welcome';
import { Store } from './../pages/Store';

import { YourStore } from "../components/Store/YourStore";
import { Finish } from "../components/Store/Finish";
import Panel from "../pages/Panel";

export const IndexRoutes = () => {
  return (
    <BrowserRouter>
      <AnimatedRoutes />
    </BrowserRouter>
  );
};

const AnimatedRoutes = () => {
  const location = useLocation();
  const { pathname } = location;

  return (
    <AnimatePresence>
      <Routes location={location} key={location.pathname}>
        <Route key={pathname} path="pedidosya/tn/panel" element={<Panel />} />
        <Route key={pathname} path="pedidosya/tn/welcome" element={<Welcome />} />

        <Route key={pathname} path="pedidosya/tn/store" element={<Store />} >
          <Route key={pathname} path="location" element={<YourStore />} />
          <Route key={pathname} path="finish" element={<Finish />} />
        </Route>
      </Routes>
    </AnimatePresence>
  );
};
