export const boilerplateFramermotion = {
    initial: 'initial',
    animate: 'animate',
    exit: 'exit'
}
const springTransition = {
    transition:{
        type: 'spring',
        damping: 10,
        mass: .75,
        stiffness: 100
    },
}

export const stiffAnimation = {
    transition: {
        duration: 1,
        delay: .2,
        bounce: 0,
        stiffness: 1000
    }
}

export const bouncyAnimation = {
    transition: {
        type: "spring",
        bounce: 1,
    }
}

// ? PEYA EXCLUSIVES
export const welcomeBar={
    ...boilerplateFramermotion,
    ...stiffAnimation,
    key: 'welcome-container',
    transition:{
        duration: 2,
        delay: .5
    },
    initial:{ 
        opacity: 0,
        translateY: "-10%"
    },
    animate:{ 
        opacity: 1,
        translateY: 0,
    },
    exit:{ 
        translateY: "-60%",
        opacity: 0,
        transition:{
            duration: 1,
            delay: .7
        }
    }
}
export const peyaCard={
    ...boilerplateFramermotion,
    ...bouncyAnimation,
    transition:{
        duration: 1,
        delay: 2
    },
    
    initial:{
        translateX: -40,
        opacity: 0,
    },
    animate:{
        translateX: 0,
        opacity:1
    }
}

export const loadingScreen ={
    ...boilerplateFramermotion,
    key:'loading-screen',
    initial:{
        opacity: 0,
        transition: {
            delay: 1
        },
    },
    animate:{
        opacity: 1,
        transition : {
            duration: .25
        }
    },
    exit:{
        opacity:1,
        transition:{
            duration: 1,
        }
    }
}



export const cityAn = {
    ...boilerplateFramermotion,
    transition:{
        type: 'spring',
        damping: 8,
        mass: .5,
        stiffness: 50,
        duration: 3,
        delay: 1.5
    },
    initial:{
        translateY: 200,
        opacity: 0,
    },
    animate:{
        translateY: 0,
        opacity:1
    },
    exit:{
        translateX: -200,
        opacity: 0
    }
}

export const buttonWelcome={
    ...boilerplateFramermotion,
    transition:{
        type: 'spring',
        damping: 8,
        mass: .5,
        stiffness: 50,
        duration: 1,
        delay: 2.5
    },
    initial:{
        translateX: -40,
        opacity: 0,
    },
    animate:{
        translateX: 0,
        opacity:1
    }
}

export const preparationError={
    ...boilerplateFramermotion,
    transition:{
        type: 'spring',
        damping: 8,
        mass: .5,
        stiffness: 150,
        duration: .75,
    },
    initial:{
        translateX: -40,
        opacity: 0,
    },
    animate:{
        translateX: 0,
        opacity:1
    },
    exit:{
        translateX: 40,
        opacity: 0
    }
}

export const leaveWelcomeImage = {
    leave: {
        transition: {
            duration: .5,
        },
        type: 'spring',
        damping: 8,
        mass: .5,
        stiffness: 50,
        translateX: -100,
        opacity: 0
    }
}

export const fieldsetDeliveryAn = {
    ...boilerplateFramermotion,
    joinSt1: {
        transition: {
            duration: .5,
        },
        type: 'spring',
        damping: 8,
        mass: .5,
        stiffness: 50,
        translateX: 0,
        opacity: 1
    },
    leaveSt1:{
        transition:{
            delay: .15,
            duration: .5
        },
        type: 'spring',
        damping: 8,
        mass: .5,
        stiffness: 50,
        translateX: -200,
        opacity: 0
    },
    
    joinSt2: {
        transition: {
            duration: 1,
        },
        type: 'spring',
        damping: 8,
        mass: .5,
        stiffness: 50,
        translateX: 0,
        opacity: 1,
        exit:{
            transition: 2,
            duration: 1
        }
    },
    initialSt2:{
        transition:{
            delay: .5,
            duration: 1
        },
        translateY: '-105%',
        translateX: '100vw'
    },
    initialSt2Big:{
        transition:{
            delay: .5,
            duration: 1
        },
        translateY: '-50vh',
        translateX: '100vw'
    }
}

export const navigationAnimations = {
    step1: {
        transition: {
            duration: .5,
        },
        type: 'spring',
        damping: 8,
        mass: .5,
        stiffness: 50,
        translateY: -40,
        opacity: 0
    },
    step2: {
        transition: {
            duration: .5,
        },
        type: 'spring',
        damping: 8,
        mass: .5,
        stiffness: 50,
        translateY: -40,
        opacity: 0
    }
}
export const welcomeMessageAn = {
    ...boilerplateFramermotion,
    ...stiffAnimation,
    key:'welcome-message-key',
    transition:{
        duration: .9,
        delay: 1.5
    },
    
    initial:{
        translateY: -40,
        opacity: 0,
    },
    animate:{
        translateY: 0,
        opacity:1
    },
    exit:{
        translateX: -500,
        opacity:0,
        transition:{
            duration: 1.5,
            delay: 1
        }
    }
}

export const failureAn = {
    ...boilerplateFramermotion,
    ...stiffAnimation,
    key:'failure-page-key',
    transition:{
        duration: 1,
        delay: 2.2
    },
    initial:{
        transition:{
            delay: 2,
        },
        translateY: -40,
        opacity: 0,
    },
    animate:{
        translateY: 0,
        opacity:1
    },
    exit:{
        opacity:0,
        transition:{
            duration: .5,
            delay: 0
        }
    }
}

export const loginAn = {
    ...boilerplateFramermotion,
    ...stiffAnimation,
    key:'login-page-key',
    transition:{
        duration: .9,
        delay: 1.2
    },
    initial:{
        transition:{
            delay: 2,
        },
        translateY: -40,
        opacity: 0,
    },
    animate:{
        translateY: 0,
        opacity:1
    },
    exit:{
        opacity:0,
        transition:{
            duration: 1,
        }
    }
}

export const shakeAnimation = {
    ...boilerplateFramermotion,
    initial:{
        y: -20
    },
    transition:{
        repeat: 1,
        repeatType: "reverse",
        duration: 2
    },
    animate:{
        y: 10,
        scale: 1.1,
    }
}


export const alertAnimation = {
    ...boilerplateFramermotion,
    initial:{ 
        opacity: 0, 
        translateX: -50 
    },
    animate:{ 
        opacity: 1, 
        translateX: 0 
    },
    exit:{ 
        opacity:0, 
        translateX: 50 
    }
}
export const leaveSlow={
    ...boilerplateFramermotion,
    transition:{
        duration: .9,
        delay: 2
    },
    exit:{ 
        opacity:0,
        transition:{
            duration: 3,
            delay: 2
        }
    }
}

export const movePage={
    ...boilerplateFramermotion,
    exit:{ 
        opacity:0,
        translateY:-200,
        transition:{
            duration: 1.5,
            delay: 0
        }
    }
}
export const LoginMessageAn = {
    ...boilerplateFramermotion,
    transition: {
        delay: .25,
        duration: 1.2,
        type: 'spring',
        damping: 8,
        mass: .4,
        stiffness: 50,
    },
    initial:{
        opacity: 0,
        translateX: -200,
        transtion:{
            duration: 1,
            delay: .5,
        }
    },
    join:{
        type: 'spring',
        damping: 8,
        mass: .5    ,
        opacity: 1,
        translateX: 0,
    },
    leave: {
        transition: {
            duration: 1.5,
            delay: .5
        },
        type: 'spring',
        damping: 8,
        mass: .1,
        stiffness: 50,
        translateX: -50,
        opacity: 0
    }
}


export const storeForms = {
    ...boilerplateFramermotion,
    transition: {
        delay: 1.5,
        duration: 1,
        type: 'spring',
        damping: 8,
        mass: .4,
        stiffness: 50,
    },
    initial:{
        opacity: 0,
        translateY: -200,
        transtion:{
            duration: 1,
            delay: 1.5,
        }
    },
    join:{
        type: 'spring',
        damping: 8,
        mass: .5    ,
        opacity: 1,
        translateY: 0,
    },
    leave: {
        transition: {
            duration: 1,
            delay: .5
        },
        type: 'spring',
        damping: 8,
        mass: .1,
        stiffness: 50,
        translateY: -50,
        opacity: 0
    },
    exit:{
        opacity:0,
        translateY: 50,
        transition:{
            duration: .6,
        }
    }
}


export const loginForm = {
    ...boilerplateFramermotion,
    transition: {
        delay: 4.5,
        duration: 1,
        type: 'spring',
        damping: 8,
        mass: .4,
        stiffness: 50,
    },
    initial:{
        opacity: 0,
        translateX: 200,
    },
    join:{
        type: 'spring',
        damping: 8,
        mass: .5    ,
        opacity: 1,
        translateX: 0,
    },
    leave: {
        transition: {
            duration: 1,
            delay: .25
        },
        type: 'spring',
        damping: 8,
        mass: .1,
        stiffness: 50,
        translateY: -50,
        opacity: 0
    },
    exit:{
        opacity:0,
        translateY: 50,
        transition:{
            duration: .6,
            delay: .25,
        }
    }
}
export const loginMessage = {
    ...boilerplateFramermotion,
    transition: {
        type: 'spring',
        damping: 8,
        mass: .4,
        stiffness: 50,
    },
    join:{
        type: 'spring',
        damping: 8,
        mass: .5    ,
    },
    leave: {
        transition: {
            duration: 1.5,
            delay: .5
        },
        type: 'spring',
        damping: 8,
        mass: .1,
        stiffness: 50,
        translateY: -50,
        opacity: 0
    }
}


export const deliveryAn = {
    ...boilerplateFramermotion,
    transition:{
        delay: 1,
        duration: .5,
    },
    initial:{
        translateY: 200,
        opacity: 0,
        // scale: .5,
    },
    animate:{
        translateY: 0,
        opacity: 1
    },
    exit:{
        transition: {
            delay: .25,
            duration: .85
        },
        translateY: 200,
        opacity: 0
    }
}

export const finishAn = {
    ...boilerplateFramermotion,
    transition:{
        type: 'spring',
        damping: 5,
        mass: .75,
        stiffness: 100,
    },
    initial:{
        translateY: 0,
        opacity: 0,
    },
    animate:{
        translateY: 0,
        opacity: 1
    },
    leave: {
        transition: {
            duration: .5,
            delay: .1
        },
        type: 'spring',
        damping: 8,
        mass: .1,
        stiffness: 50,
        translateY: -50,
        opacity: 0
    }
}





// ? List animations

export const fallingAnimation = {
    ...boilerplateFramermotion,
    initial:{
        opacity: 0,
        translateY: -50
    },
    animate:{
        opacity: 1,
        translateY: 0
    },
    exit:{
        opacity:0,
        translateY: 50
    }
}

export const lineAnimation = {
    ...boilerplateFramermotion,
    initial:{
        opacity: 0.2,
        scaleY: 0
    },
    animate:{
        opacity: 1,
        scaleY: 1.6
    },
    exit:{
        opacity: 0.2,
        scaleY: 0
    }
}

export const customTimeAn = {
    ...boilerplateFramermotion,
    ...springTransition,
    initial:{
        opacity: 0,
        translateY: -40,
    },
    animate:{
        opacity: 1,
        translateY: 0,
    },
    exit:{
        opacity: 0,
        translateY: -20
    }
}

export const comeFromLeftAn = {
    ...boilerplateFramermotion,
    ...springTransition,
    initial:{
        opacity: 0,
        translateX: -10,
    },
    animate:{
        opacity: 1,
        translateX: 0
    },
    exit:{
        opacity: 0,
        translateX: -10
    }
}

export const dayPickAn = {
    ...boilerplateFramermotion,
    initial:{
        opacity: 0,
        scaleY: 0,
    },
    animate:{
        opacity: 1,
        scaleY: 1,
    },
    exit:{
        opacity: 0,
        scaleY: 0
    }
}