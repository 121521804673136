import React, { useEffect } from 'react'
import { useSearchParams } from 'react-router-dom';
import { useState } from 'react';
import { useAuthStore, useNavStore } from './../../store';
import { getUserHasTimezoneAssigned } from '../form.service';

export const useNav = () => {
  const [searchParams] = useSearchParams();

  const userIdCache = useAuthStore(state=>state.initialBody.userId)
  const userId = searchParams.get('userId');
  const [isLoading, setIsLoading] = useState(true);
  const setCacheTimezone = useNavStore((state) => state.setPartnerHasTimezoneAssigned);

  const [description, setDescription] = useState(noHourRangeConfiguratedMessages);

  const [currentStatus, setCurrentStatus] = useState(null);
  const alert = useAuthStore((state) => state.alert);

  const styles = {
    display: 'inline-table',
    verticalAlign: 'top',
    marginTop: '4rem',
    marginLeft: '4rem',
  }; // This is due lib using js css

  const getInitialStateTimezone = async () => {
    setCacheTimezone([]);
    try {
      const result = await getUserHasTimezoneAssigned(userId || userIdCache);
      const { success, message, data } = result;
      if (success) {
        setDescription(hourRangeConfiguratedMessages);
        setCacheTimezone(data);
      }
    } catch (error) {
      console.log(error);
      setCacheTimezone([]);
    }
  };

  useEffect(() => {
    getInitialStateTimezone();
    setIsLoading(false);
  }, []);

  useEffect(() => {
    const { message: alertMessage, success: alertSuccess } = alert;
    setCurrentStatus((alertMessage || !alertSuccess) && 'error');
  }, [alert]);

  return { isLoading, description, styles, currentStatus, description };
};

const hourRangeConfiguratedMessages = [
  {
    title: 'Dirección de tu tienda',
    subtitle: 'Ingresá a donde retiramos tus paquetes',
  },
  {
    title: 'Horarios de tu tienda',
    subtitle: 'Indicá cuándo podemos retirar tus paquetes.',
  },
  {
    title: 'Opciones de envío',
    subtitle: 'Elegí qué envíos querés ofrecer en tu tienda.',
  },
];

const noHourRangeConfiguratedMessages = [
  {
    title: 'Dirección de tu tienda',
    subtitle: 'Ingresá a donde retiramos tus paquetes',
  },
  {
    title: 'Horarios de tu tienda',
    subtitle: 'Indicá cuándo podemos retirar tus paquetes.',
  },
  {
    title: 'Solicitud de repartidores',
    subtitle: 'Configurá cómo solicitar repartidores para los envíos de tu tienda.',
  },
];
