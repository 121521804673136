import moment from 'moment';
import { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import { useSearchParams } from 'react-router-dom';
import { useAuthStore, useNavStore, useStorageFormStore } from "../../store";
import { saveLocationsData } from "../delivery.service";
import { addZeroIfBelowTen } from '../shared/utils.service';

export const useDelivery = (isRequestDelivery = false, stageChangeFn) => {
  const userId = useAuthStore((state) => state?.initialBody?.userId);
  const [searchParams] = useSearchParams();
  const userIdParam = searchParams.get('userId');
  const store = useStorageFormStore((state) => state?.store);
  const addData = useStorageFormStore((state) => state.addData);
  const navigate = useNavigate();
  const setAlert = useAuthStore((state) => state?.setAlert);
  const [isLoading, setIsLoading] = useState(false);
  const [isAutomatic, setIsAutomatic] = useState(store?.isAutomatic);

  const defaultDeliveryType = isRequestDelivery ? 'sameDayShip' : 'mixed';
  const [deliveryType, setDeliveryType] = useState(store?.deliveryType || defaultDeliveryType);
  const [requestDealerType, setRequestDealerType] = useState(
    store?.isAutomatic ? 'automatic' : 'manual'
  );
  const [hourRange, setHourRange] = useState({ entry: '11:00', exit: '16:00' });
  const [areHoursSynced, setAreHoursSynced] = useState(false);

  const timezoneSelected = useStorageFormStore((state) => state.store.timeSchedule);
  const timezoneAssigned = useNavStore((state) => state.partnerHasTimezoneAssigned);
  const timeFormat = 'hh:mm';

  const isSynced = (introTime, exitTime) => {
    return timezoneSelected.every((day) => {
      const { openingHour, openingMinute, closingHour, closingMinute, open } = day;
      if (open) {
        const openTime = `${addZeroIfBelowTen(openingHour)}:${addZeroIfBelowTen(openingMinute)}`;
        const closeTime = `${addZeroIfBelowTen(closingHour)}:${addZeroIfBelowTen(closingMinute)}`;

        const openingFormatted = moment(openTime, timeFormat);
        const closingFormatted = moment(closeTime, timeFormat);
        const openingCacheFormatted = moment(introTime, timeFormat);
        const closingCacheFormatted = moment(exitTime, timeFormat);
        const validOpening = openingFormatted.isBefore(openingCacheFormatted);
        const validClosing = closingFormatted.isAfter(closingCacheFormatted);
        return validOpening && validClosing;
      }
      return true;
    });
  };

  useEffect(() => {
    if (timezoneAssigned.length === 1) {
      const { deliveryTimeFrom, deliveryTimeTo } = timezoneAssigned[0];
      setHourRange({ entry: deliveryTimeFrom, exit: deliveryTimeTo });
    }
    const areAllTimezoneSynced = timezoneAssigned.every(({ deliveryTimeFrom, deliveryTimeTo }) => {
      return isSynced(deliveryTimeFrom, deliveryTimeTo);
    });
    setAreHoursSynced(areAllTimezoneSynced);
  }, [timezoneAssigned]);

  const onReturn = () => {
    navigate(`../location?userId=${userId}&status=success`);
  };

  const onSubmit = async (e) => {
    e.preventDefault();
    const {
      address,
      city,
      instructions,
      coordinates,
      additionalNotes,
      managerName,
      phoneNumber,
      preparationTime,
      timeSchedule,
    } = store;
    const isAutomaticByRequestDelivery = requestDealerType === 'manual' ? false : true;
    const deliveryBodyReplacer = {
      address: address,
      city: city,
      instructions: instructions,
      managerName: managerName,
      phoneNumber: phoneNumber,
      preparationTime: preparationTime,
      timeSchedule: timeSchedule,
      isAutomatic: isRequestDelivery ? isAutomaticByRequestDelivery : isAutomatic,
      deliveryType: deliveryType,
      additionalNotes: additionalNotes,
      coordinates: coordinates,
    };
    setIsLoading( true )
    addData( deliveryBodyReplacer )
    try {
      const result = await saveLocationsData ( deliveryBodyReplacer, userId || userIdParam);
      if (result) {
        const { success } = result;
        if( success ){
          stageChangeFn(4);
          setTimeout(() => {
            navigate(`../finish?userId=${userId || userIdParam}`)
          }, 300);
        }
      }
    } catch (error) {
      setAlert({ message: 'Ocurrió un error al contactar el servidor.', success: false})
    }
    setIsLoading(false)
  };

  const handleRadioChange = (e) => {
    const { id } = e.target;
    const safeOptions = ['sameDayShip', 'hourRange', 'mixed'];
    if (safeOptions.includes(id)) {
      setDeliveryType(id);
    }
  };

  const handleRadioChangeRequestForm = (e) => {
    const { id } = e.target;
    const safeOptions = ['automatic', 'manual'];
    if (safeOptions.includes(id)) {
      setRequestDealerType(id);
    }
  };

  const handleCheckboxChange = (e) => {
    console.log(deliveryType);
    const { id } = e.target;
    if (deliveryType === id) {
      setDeliveryType('');
    }
    if (deliveryType !== id) {
      setDeliveryType(id);
    }
  };

  return  {
    hourRange,
    isLoading,
    onReturn,
    isAutomatic,
    setIsAutomatic,
    deliveryType,
    requestDealerType,
    onSubmit,
    handleRadioChange,
    handleRadioChangeRequestForm,
    handleCheckboxChange,
    areHoursSynced,
  }
};