import React from 'react'
import { useWelcome } from '../services/hooks/useWelcome'
import { motion } from 'framer-motion'
import { buttonWelcome, failureAn } from '../services/shared/framermotion.service'
import reject from '../assets/rejectionPeya.svg'
const Failure = () => {
  const { onRetry } = useWelcome()
  return (
    <motion.section  {...failureAn }  className='failure-container'>
        <img src={reject} alt="Rechazado" />
        <h2>No hemos podido iniciar tu sesión</h2>
        <h3>Por favor, inténtalo nuevamente</h3>
        <motion.button
            key='retry--button'
            className='fw-bold py-3'
            onClick={onRetry}
            {...buttonWelcome}
        >
            Reintentar
        </motion.button>
    </motion.section>
  )
}

export default Failure