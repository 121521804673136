import React from 'react';
import { usePanelStore, useGeneralStore } from '../../../../store';
import { FadeIn } from 'react-package';

const Tab = ({ text, id }) => {
  const { ordersActiveTab, setOrdersActiveTab } = usePanelStore(state => state);

  return (
    <div
      onClick={() => setOrdersActiveTab(id)}
      className={
        ordersActiveTab === id ?
          "tabs__tab active" : "tabs__tab"
      }
    >
      {text}
    </div>
  )
}

const Tabs = () => {
  const { ordersActiveTab } = usePanelStore(state => state);
  const color = useGeneralStore(state => state.settings.colors.primary);

  return (
    <div className="tabs">
      <FadeIn
        noXY
        className={`indicator ${ordersActiveTab}`}
        style={{ backgroundColor: `#${color}` }}
      />

      <Tab
        text="Todos"
        id="all"
      />
      {/* <Tab
        text="Por colectar"
        id="availableForCollect"
      /> */}
      <Tab
        text="Pendiente de retiro"
        id="pending"
      />
      <Tab
        text="En entrega"
        id="confirmed"
      />
      <Tab
        text="Entregadas"
        id="delivered"
      />
      <Tab
        text="Canceladas"
        id="error"
      />
    </div>
  );
}

export default Tabs;
