import { post as restPost, get as restGet } from '../services/shared/rest.service'

export const getInitial = async(userId) =>{
  try {
    const endpoint = `/onboarding/initialState`;
    const params = { userId: userId }
    const result = await restGet( endpoint, params );
    return result.data;
    
  } catch (error) {
    throw error.error
  }
};

export const getUserHasTimezoneAssigned = async (userId) => {
  try {
    const endpoint = `/onboarding/proposals`;
    const params = { userId: userId };
    const result = await restGet(endpoint, params);
    return result.data;
  } catch (error) {
    throw error.error;
  }
};

export const postCredentials = async(userId,body) =>{
  try {
    const endpoint = `/onboarding/authenticate`;
    const params= { userId: userId }
    const result = await restPost( endpoint, body, params );
    return result.data;
  } catch (error) {
    return error
  }
}

export const postLocations = async(userId,body) =>{
  try {
    const endpoint = `/onboarding/validateLocation`;
    const params= { userId: userId }
    const result = await restPost( endpoint, body, params );
    return result.data;
  } catch (error) {
    return error
  }
}



export const getCountryCoords = (countryName) => {
  const countriesCoords = {
    "Argentina": {lat: -34.61315, lng: -58.37723},
    "España": {lat: 40.4165, lng: -3.70256},
  }
  return countriesCoords[countryName];
};

export const clientIdProps = {
  htmlProps: {
    label: 'Client ID',
    id: 'clientId',
    placeholder: 'FNB9A',
    margins: 'mb-2 mx-4'
  }
}
export const clientSecretProps = {
  htmlProps: {
    label: 'Client Secret',
    id: 'clientSecret',
    placeholder: '************',
    margins: 'mb-2 mx-4'
  }
}
export const emailProps = {
  htmlProps: {
    label: 'Email',
    id: 'username',
    placeholder: 'usuario@clientid.com',
    margins: 'mb-2 mx-4'
  }
}
export const passwordProps = {
  htmlProps: {
    label: 'Contraseña',
    id: 'password',
    placeholder: '********',
    margins: 'mb-2 mx-4'
  }
}