import React from 'react'
import { StorePanel } from '../components/Store/StorePanel';
import { Outlet, useLocation, useSearchParams } from 'react-router-dom';
import Failure from './Failure';

export const Store = () => {
  const [searchParams] = useSearchParams();
  const status = searchParams.get('status');
  const location = useLocation();
  const { pathname } = location;

  if ((status === 'fail' || !status) && pathname === '/pedidosya/tn/store/location') {
    return <Failure />;
  }
  return (
    <main key='store-page-container' className='full-sized-container' >
      <div className="row h-99">
        <div className="col-4 col-xxl-3 rounded h-100 d-flex justify-content-center">
          <StorePanel/>
        </div>
        <div className="col-8 col-xxl-9 xl-mt-5 overflow-hidden d-flex flex-column ml-5 ml-xxl-1 h-100">
          <Outlet/>
        </div>
      </div>
    </main>
  )
}
