import React from 'react';

// Components
import Tabs from "./Tabs"
import Filter from "./Filter"
import Table from './Table';

// Icons
import Pagination from './Pagination';

const Index = () => {
  return (
    <div id="orders" className="content-wrapper">
      <Tabs />
      <Filter />
      <Table />
      <Pagination />
    </div >
  );
}

export default Index;
