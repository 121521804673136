import { useEffect, useState } from 'react'
import { useSearchParams, useNavigate } from 'react-router-dom';
import { useAuthStore, useNavStore } from '../../store';
import { getInitial } from './../form.service';



export const useWelcome = () => {
  const [searchParams] = useSearchParams();
  const userId = searchParams.get("userId");
  const userIdCache = useAuthStore(state=>state.initialBody?.userId)
  const [isLoading, setIsLoading] = useState(true)
  const setCache = useAuthStore((state) => state.setInitialBody);
  const [initialBody, setInitialBody] = useState({
    appKey: '',
    appToken: '',
    ecommerce: 'tiendanube',
  });
  const [animateLeave, setAnimateLeave] = useState();
  const navigate = useNavigate();
  const setAlert = useAuthStore((state) => state.setAlert);

  async function getInitialState(){
    setIsLoading(true)
    if(userId){
      try {
        const result = await getInitial(userId)
        const { user } = result.data
        const { userId: userIdResult, storeUrl, appKey, consumerKey } = user
        setInitialBody({
          ecommerce: 'tiendanube',
          appKey: appKey || 'fail',
          consumerKey: consumerKey || 'fail',
        });
        setCache({
          userId: userIdResult,
          storeUrl: storeUrl,
          ecommerce: 'tiendanube',
          appKey: appKey || 'fail',
          consumerKey: consumerKey || 'fail',
        });
        setIsLoading(false)
      } catch (error) {
        console.log(error)
        setIsLoading(false)
      }
    } else {
      console.error('Error, no userId was provided.');
    }
  }

  useEffect(() => {
    setAlert({ success: null, message: null })
    getInitialState()
  }, [])

  const onContinue = async () => {
    // const peyaBaseUrl = 'https://stg-courier-web.pedidosya.com.uy/credentials';
    const peyaBaseUrl = 'https://envios.pedidosya.com.ar/credentials';
    const { appKey, consumerKey } = initialBody;
    setAnimateLeave(true);
    setTimeout(() => {
      window.location.replace(
        `${peyaBaseUrl}?appKey=${appKey}&ecommerce=tiendanube&consumerKey=${consumerKey}`
      );
    }, 800);
  };
  const onRetry = () => {
    setAnimateLeave(true);
    setTimeout(() => {
      navigate(`/pedidosya/tn/welcome?userId=${userId || userIdCache}`);
    }, 350);
  }

  return { isLoading, userId, onContinue, onRetry, animateLeave }
}
