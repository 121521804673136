import React, { useState, useEffect } from 'react';
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
} from 'chart.js';
import { Bar } from 'react-chartjs-2';
import { useGeneralStore } from '../../../../../store';

ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend
);

const optionsBar = {
    // plugins: {
    //     title: {
    //         display: true,
    //         text: 'Pedidos por mes',
    //     },
    // },
    responsive: true,
    interaction: {
        mode: 'index',
        intersect: false,
    },
    scale: {
        x: {
            stacked: true,
        },
        y: {
            stacked: true,
        },
    },
    maintainAspectRatio: false,

};

const Index = ({ title, data, money }) => {
    const { primary } = useGeneralStore(state => state.settings.colors);
    const { darkmode } = useGeneralStore(state => state);

    return (
        <div className='stats-item-wrapper'>
            <div className="stats-title">
                {title}
                <span
                    style={{
                        backgroundColor: `#${primary}`,
                        borderColor: `#${primary}`,
                    }}
                >{money && "$"}{data.total}</span>
            </div>
            <div className="stats-item">
                <Bar
                    height={300}
                    options={optionsBar}
                    data={data}

                />
            </div>
        </div>
    );
}

export default Index;
