import React, { useEffect,useState } from 'react';
import { loadingScreen } from '../../services/shared/framermotion.service'
import { motion, AnimatePresence } from 'framer-motion';
import { RiInformationFill } from 'react-icons/ri'
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { stopPropagation } from '../../services/shared/utils.service'
import peyaLoader from '../../assets/peyaloader.png'
export const InformationTooltip = ({
  tooltipTxt,
  extraClassIcon = " ",
  extraClassContainer = " ",
  extraClassReactTooltip = " ",
  idName,
  anchorTarget,
}) => {
  return (
    <a 
    href={anchorTarget} 
    target="_blank" 
    rel="noopener noreferrer"
    >
      <span className={`infoTooltip ${extraClassContainer}`}>
        <OverlayTrigger
          key={'overlay-ux'}
          placement={'top'}
          overlay={<Tooltip id='tooltip-top' data-testid='tooltip-txt'>{tooltipTxt}</Tooltip>}
        >
          <span
            className={`icon-child ${extraClassIcon}`}
            data-tip
            onClick={stopPropagation}
            data-for={idName}>
            <RiInformationFill color='#62628A'/>
          </span>
        </OverlayTrigger>
      </span>
    </a>
  );
};


export const Input = ({functionalProps,htmlProps}) => {
  const {
    type='text',
    value,
    checked,
    tooltipProps,
    isFailed=false,
    failedMessage,
    isDisabled =false,
    firstExecution,
    onChange,
    onBlur
  } = functionalProps
  const {
    label,
    sublabel,
    margins='mb-3 mx-4',
    id,
    placeholder,
    extraPadding,
    colSize='col-4' ,
    tabIndex
  } 
  = htmlProps
  const [failedField,setFailedField] = useState()
  useEffect(() => {
    if(!isFailed && firstExecution===true){
      setFailedField(true)
    }else{
      setFailedField(false)
    }
  }, [isFailed,firstExecution])


  return (
    <>
      <label
        className={`${colSize} ${margins} d-flex ${type!=='checkbox' ? 'flex-column' : 'flex-row'} text-sm font-bold`}
        htmlFor={id}
      >
        <div className={`fw-bold d-flex justify-content-between mt-2 purple-text ${type==='checkbox' && 'd-none'}`}>
          {label || placeholder}
        </div>
        <input
          id={id}
          tabIndex={tabIndex}
          checked={checked}
          onBlur={onBlur}
          disabled={isDisabled}
          onChange={onChange}
          value={value}
          aria-label={label}
          placeholder={placeholder || label}
          type={type}
          className={ `border
          isDisabled-${isDisabled} isFailed-${failedField} ${extraPadding} ${type==='checkbox' && 'form-check-input'}
        ` }
        />
        <div className={`checkbox-text d-flex flex-column justify-content-between ${type!=='checkbox' && 'd-none'}`}>
          <h4 className='fw-bold'>{label}</h4>
          <h5>{sublabel}</h5>
        </div>
        {failedMessage && 
          <AnimatePresence>
            <motion.span key='is-failed-message' className="isFailed-message">
              {failedMessage}
            </motion.span>
          </AnimatePresence>
        }
        <div className={`infoTooltip tooltip-basic ${type === 'checkbox' && 'tooltip-checkbox'}`}>
          {tooltipProps && <InformationTooltip {...tooltipProps} />}
        </div>
      </label>
    </>
  )
}
export const InputsRadioAsButton = ({functionalProps,htmlProps,isCustom=false}) => {
  const {
    onChange,
    checked,
    isDisabled
  } = functionalProps
  const {
    label,name, extraClassName,
    id, value, tabIndex
  } = htmlProps

  return (
    <div className={`radioBtn-wrapper ${extraClassName}`} key={tabIndex}>
      <input
        type="radio"
        name={name}
        id={id}
        disabled={isDisabled}
        value={value}
        onChange={onChange}
        checked={checked}
        tabIndex={-1}
      />
      <label htmlFor={id}>
        {label}
        </label>
    </div>
  )
}

export const InputsSwitch = ({functionalProps,htmlProps}) => {
  const { 
    onChange, 
    checked, 
  } = functionalProps
  const { 
    label,name, 
    id, value 
  } = htmlProps
  return (
    <div className="form-check form-switch">
      <input
        className="form-check-input"
        type="checkbox"
        role="switch"
        id={id}
        value={value}
        name={name}
        onChange={onChange}
        tabIndex={-1}
        checked={checked} />
      <label className="form-check-label label-bold" htmlFor={id}>{label}</label>
    </div>
  )
}

export const InputsCheckboxAsButton = ({functionalProps,htmlProps}) => {
  const { 
    onChange, 
    checked, 
    isDisabled 
  } = functionalProps
  const { 
    label,name, extraClassName, 
    id, placeholder,value 
  } = htmlProps
  return (
    <div className={`label-checkbox-day-container d-flex flex-row align-items-center ${extraClassName}`}>
      <label htmlFor={id}>{label || placeholder}</label>
      <div className="form-check form-switch">
        <input
          className="form-check-input"
          type="checkbox"
          role="switch"
          id={id}
          name={name}
          value={value}
          checked={checked}
          onChange={onChange}
          disabled={isDisabled}
        />
      </div>
    </div>
  );
};

export const InputsCheck = ({
  id,
  value,
  labelTxt,
  descriptionTxt = '',
  onChangeFn,
  checked,
  disabled,
  extraClassname = '',
}) => {
  return (
    <div className={`form-check mb-1 mb-xl-3 d-flex align-items-start ${extraClassname}`}>
      <input
        checked={checked}
        onChange={onChangeFn}
        disabled={disabled}
        className="form-check-input"
        type="checkbox"
        id={id}
        value={value}
      />
      <label className="form-check-label" for={id}>
        <h3>{labelTxt}</h3>
        <h4>{descriptionTxt}</h4>
      </label>
    </div>
  );
};

export const InputsRadio = ({ id, name, value, labelTxt, descriptionTxt='', onChangeFn, checked, disabled, extraClassname='' }) => {
  return (
    <div className={`form-check mb-1 mb-xl-3 d-flex align-items-start ${extraClassname}`}>
      <input className="form-check-input" type="radio" name={name} id={id} value={value} checked={checked} onChange={onChangeFn} disabled={disabled} />
      <label className="form-check-label" htmlFor={id}>
        <h3>{labelTxt}</h3>
        <h4>{descriptionTxt}</h4>
      </label>
    </div>
  )
}

export const SpinnerPeya = () => {
  return (
    <motion.aside key='peya-spinner' id='spinner-peya' {...loadingScreen}>
      <img id='apploader' src={peyaLoader} alt="Cargando" />
    </motion.aside>
  )
}
