import React from 'react';

// Elements
// import { Select } from "react-package"
import { useGeneralStore, usePanelStore } from '../../../../store';
import { Pagination } from "react-package"

const resultPerPageOptions = [
  { id: 10, label: '10' },
  { id: 20, label: '20' },
  { id: 30, label: '30' },
  { id: 40, label: '40' },
  { id: 50, label: '50' },
]

const PaginationWrapper = () => {
  const { totalPages, activePage, resultsPerPage, setResultsPerPage, setActivePage } =
    usePanelStore(state => state);

  const color = useGeneralStore(state => state.settings.colors.primary);

  return (
    <div className="pagination-wrapper">
      <div className="results-per-page">
        {/* <div className="label">
          Resultados por página:
        </div> */}
        {/* <Select
          reversed
          value={resultsPerPage}
          options={resultPerPageOptions}
          onChange={(e) => setResultsPerPage(e.target.value)}
        /> */}
      </div>
      <Pagination
        totalPages={totalPages}
        activePage={activePage}
        handle={(e) => setActivePage(e)}
        color="#f52f41"
      />
    </div>
  );
}

export default PaginationWrapper;
