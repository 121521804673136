import React, { useState } from 'react'
import { useStore } from '../../services/hooks/useStore';
import { nameProps, notesProps, phoneProps } from '../../services/store.service';
import { Input, InputsCheckboxAsButton } from './../shared/UI';
import { AnimatePresence, motion } from 'framer-motion';
import { preparationError, storeForms } from '../../services/shared/framermotion.service';
import { MapContainer } from './Map';
import { additionalNotesProps } from './../../services/store.service';
import { dayLabels, dayNames } from '../../services/delivery.service';
import { HoursSchedule } from './HoursSchedule';
import { PreparationInMinutes } from './PreparationInMinutes';
import { useNavigate } from 'react-router';
import { validatePhone } from '../../services/shared/utils.service';
import LoaderGif from '../../assets/loading.svg'
import { useSearchParams } from 'react-router-dom';
import { useStorageFormStore, useNavStore } from '../../store';
import { Delivery } from './Delivery';
import { RequestDeliveries } from './RequestDeliveries';
export const YourStore = () => {
  const {
    submitStepOneAndTwo,
    isLoading,
    isFormDisabled,
    stage,
    setStage,
    body,
    setBody,
    onChange,
    exitAnimation,
    defaultCoordinates,
    locationInitialCoordinates,
    tabIndex,
    // ? PART 2
    handleAvailableDays,
    openDays,
    handleStartDate,
    handleEndDate,
    startDate,
    endDate,
    weekDays,
    preparationTime,
    timeErrors,
    allCompleted,
    handlePreparationChange,
    isCustomTime,
    showWarning,
    isSubmitDisabled,
    userId,
    handleStageChange,
  } = useStore()
  const cacheTimezone = useNavStore((state) => state.partnerHasTimezoneAssigned);

  return (
    <motion.section
      id='store-form'
      key='store-form'
      className='row d-flex justify-content-center'
      {...storeForms}
      animate={exitAnimation ? 'leave' : 'join'}
      variants={storeForms}
    >
      <div>
        {stage === 1 && 
          <AnimatePresence>
            <h2 className="purple-text">Ingresá a dónde retiramos tus paquetes</h2>
            <StoreInfo
              body={body}
              setBody={setBody}
              mapData={{ defaultCoordinates: defaultCoordinates, locationInitialCoordinates: locationInitialCoordinates }}
              tabIndex={tabIndex}
              onChange={onChange}
              key='store-info-container-component'
              isLoading={isLoading}
              isFormDisabled={isFormDisabled}
              nextStage={() => handleStageChange(2)}
              userId={userId}
            />
          </AnimatePresence>
        }

        {stage === 2 && 
          <fieldset className="form-scroll-store">
            <h2 className="purple-text">Indicá cuándo podemos retirar tus paquetes</h2>
            <AnimatePresence>
              <StoreTimes
                key='store-times-container'
                isLoading={isLoading}
                handleAvailableDays={handleAvailableDays}
                timeErrors={timeErrors}
                openDays={openDays}
                weekDays={weekDays}
                preparationTime={preparationTime}
                startDate={startDate}
                endDate={endDate}
                handleStartDate={handleStartDate}
                handleEndDate={handleEndDate}
                allCompleted={allCompleted}
              />
              <fieldset key='time-preparation-container' className='shadow-sm minutes-container '>
                <label className='fc-dark mt-1'>
                  ¿Cuánto tiempo demorás en preparar tus paquetes?
                </label>
                <PreparationInMinutes
                  onChange={handlePreparationChange}
                  actualValue={preparationTime}
                  isCustomTime={isCustomTime}
                  showWarning={showWarning}
                />
              </fieldset>


            </AnimatePresence>
          </fieldset>
        }

        {stage === 2 && 
          <div className='d-flex justify-content-end stage-2-confirm'>
            <button disabled={isLoading} onClick={(e) => handleStageChange(1)} className=' mt-3 me-4 mt-xxl-5 btn-width btn btn-outline-secondary'>
              Volver
            </button>
            <button
              className={`isDisabled-${isSubmitDisabled} btn-onboarding mt-3 mt-xxl-5`}
              onClick={() => submitStepOneAndTwo()}
              disabled={isSubmitDisabled}>
              { !isLoading ? 'Continuar' : <img src={LoaderGif} alt="Loading spinner" />}
            </button>
          </div>
        }

        {stage === 3 && (
          <>
            {cacheTimezone.length > 0 ? (
              <Delivery stageChangeFn={handleStageChange} />
            ) : (
              <RequestDeliveries stageChangeFn={handleStageChange} />
            )}
          </>
        )}
      </div>
    </motion.section>
  )
}

const StoreTimes = ({
  handleAvailableDays, timeErrors,
  weekDays,
  startDate, endDate,
  handleStartDate, handleEndDate,
  preparationTime
}) => {
  return (
    <fieldset key='store-times' className="weekdays-container mb-xxl-3 mb-1 ">
      <div className="d-flex flex-row justify-content-between">
        <div className="radioAsBtns-wrapper d-flex flex-column justify-space-between mb-2 mb-xxl-4">
          {weekDays.map((eachDay, dayIndex) => {
            if (dayIndex === 0 || dayIndex === 6) {
              return null;
            }
            return (
              <div className="d-flex flex-row align-items-center mb-2 mb-xxl-3">
                <InputsCheckboxAsButton
                  htmlProps={{
                    label: dayLabels[dayIndex],
                    id: `day-${eachDay.day}`,
                    extraClassName: 'rounded mx-2 mx-xxl-3 mt-1',
                    value: eachDay.day,
                    name: 'Tiempo de preparación',
                  }}
                  functionalProps={{
                    checked: eachDay.open,
                    onChange: (e) => handleAvailableDays(e, eachDay),
                    isDisabled: preparationTime === 0,
                  }}
                  key={`day ${eachDay.day}`}
                />
                <HoursSchedule
                  key={dayIndex}
                  day={eachDay}
                  handleStartDate={handleStartDate}
                  startDate={startDate}
                  handleEndDate={handleEndDate}
                  endDate={endDate}
                  timeErrors={timeErrors}
                  index={dayIndex + 10}
                />
              </div>
            );
          })}
        </div>
        <div className="radioAsBtns-wrapper d-flex flex-column-reverse justify-content-end justify-space-between mb-2 mb-xxl-4">
          {weekDays.map((eachDay, dayIndex) => {
            if (dayIndex === 0 || dayIndex === 6) {
              return (
                <div className="d-flex flex-row align-items-center mb-2 mb-xxl-3">
                  <InputsCheckboxAsButton
                    htmlProps={{
                      label: dayLabels[dayIndex],
                      id: `day-${eachDay.day}`,
                      extraClassName: 'rounded mx-2 mx-xxl-3 mt-1',
                      value: eachDay.day,
                      name: 'Tiempo de preparación',
                    }}
                    functionalProps={{
                      checked: eachDay.open,
                      onChange: (e) => handleAvailableDays(e, eachDay),
                      isDisabled: preparationTime === 0,
                    }}
                    key={`day ${eachDay.day}`}
                  />
                  <HoursSchedule
                    day={eachDay}
                    handleStartDate={handleStartDate}
                    startDate={startDate}
                    handleEndDate={handleEndDate}
                    endDate={endDate}
                    timeErrors={timeErrors}
                    index={dayIndex + 20}
                  />
                </div>
              );
            }
          })}
        </div>
      </div>
    </fieldset>
  )
}


const StoreInfo = ({ body, setBody, mapData, tabIndex, onChange, isLoading, isFormDisabled, nextStage, userId }) => {
  
  const { defaultCoordinates, locationInitialCoordinates } = mapData
  const [showWarning, setShowWarning] = useState(false)
  const isOnCoverage = useStorageFormStore((state) => state.isOnCoverage)
  const [searchParams] = useSearchParams();
  const userIdParam = searchParams.get('userId');
  const navigate = useNavigate()
  const onReturn = () => {
    navigate(`../../pedidosya/tn/welcome?userId=${userIdParam || userId}`);
  }
  const onBlur = () => {
    const { phoneNumber } = body;
    setShowWarning(!validatePhone(phoneNumber));
  }
  return (
    <>
      <div className="row  rounded py-4 py-xxl-5 mt-xxl-1 mb-xxl-5 d-flex justify-content-center"
        key='store-info-container'>
        <div className="row">
          <div className="col-6">
            <div className="mt-input"></div>
            <Input
              {...additionalNotesProps}
              functionalProps={{
                onChange: onChange,
                value: body?.additionalNotes,
                isLoading: isLoading
              }}
            />
            <Input
              {...phoneProps}
              functionalProps={{
                onBlur: onBlur,
                onChange: onChange,
                value: body?.phoneNumber,
                isLoading: isLoading,
                tabIndex: 2,
              }}
            />
            <AnimatePresence>
              {showWarning && (
                <motion.div {...preparationError} className="error-phone ms-4 fw-semibold">
                  Hasta 14 caracteres numéricos.{' '}
                  {body?.phoneNumber[0] === '0' && 'No iniciar con 0.'}
                </motion.div>
              )}
            </AnimatePresence>
          </div>
          <div className="col-5 map-container-parent ">
            <MapContainer
              body={body}
              setBody={setBody}
              centerCoordinates={defaultCoordinates}
              locationInitialCoordinates={locationInitialCoordinates}
              tabIndex={tabIndex}
            />
          </div>
        </div>

        <div className="row">
          <Input
            {...nameProps}
            functionalProps={{
              onChange: onChange,
              value: body?.managerName,
              isLoading: isLoading,
            }}
          />
          <Input
            {...notesProps}
            functionalProps={{
              onChange: onChange,
              value: body?.instructions,
              isLoading: isLoading
            }}
          />
        </div>


      </div>
      <footer className='d-flex justify-content-end location-footer align-items-center'>
        <button type="button" onClick={onReturn} className='btn btn-outline-secondary py-25 px-4 me-4.5'>Volver</button>
        <button
          onClick={nextStage}
          className={`save btn-onboarding my-2 isDisabled-${isFormDisabled || !isOnCoverage}`}
          disabled={!isOnCoverage}
        >
          {!isLoading ? 'Continuar' : <img src={LoaderGif} alt="Loading spinner" />}
        </button>
      </footer>
    </>
  )
}