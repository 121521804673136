import React from 'react';

// Icons
import {  BsCheckCircleFill } from "react-icons/bs";

const Index = ({ visible, type, message }) => {
    return (
        <div
            className={`notification ${type} ${visible ? "visible" : ""}`}
        >
            <div delay={0.2} className="notification__icon">
                <BsCheckCircleFill />
            </div>
            <div delay={0.3} className="notification__content">
                {message}
            </div>
        </div>
    );
}

export default Index;
